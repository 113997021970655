import React, { Component } from "react";


export default class Footer extends Component{
	constructor(props){
         super(props);
        this.state={
			showCategoryFilterBox: (window.innerWidth >= 768) ? 'show' : 'hide',
				showColorFilterBox: (window.innerWidth >= 768) ? 'show' : 'hide',
				showPriceFilterBox: (window.innerWidth >= 768) ? 'show' : 'hide',
				
		}		 
	}
		
		toggleFilterBox(name){
			
		switch(name){
			case 'category':
				(this.state.showCategoryFilterBox == 'show') ? this.setState({showCategoryFilterBox:'hide'}) : this.setState({showCategoryFilterBox:'show'});
				break;
			case 'color':
			
				(this.state.showColorFilterBox == 'show') ? this.setState({showColorFilterBox:'hide'}) : this.setState({showColorFilterBox:'show'});
				break;
			case 'price':
				(this.state.showPriceFilterBox == 'show') ? this.setState({showPriceFilterBox:'hide'}) : this.setState({showPriceFilterBox:'show'});
				break;
			default:
				break;
		}
		
		
	}
		
 render() {
	  return(


	
		 <div className="footers">
		<section className="footer"  id="footer">
			<div className="container">
				<div className="row">
					<div className="col-md-3">
						<div className="use_links" onClick={()=>this.toggleFilterBox('category')}>
							<h5 className="mb-4 footer-heading">USEFUL LINKS</h5><i className={this.state.showCategoryFilterBox ==="show"? "fas fa-angle-down pull-right":"fas fa-angle-right pull-right"}></i>
							<div className={this.state.showCategoryFilterBox == 'show'? "filter-sidepanel-container-active" : "filter-sidepanel-container" }>
							<ul className="footer-menu">
								<li><a href="/">Home</a></li>
								<li><a href="/catalog">Product</a></li>
								<li><a href="/SiteService">Services</a></li>
								<li><a href="/SampleKit">Sample Kit</a></li>
								<li><a href="/aboutus">About Us</a></li>
								<li><a href="/contact">Contact Us</a></li>
								<li><a href="/PrivacyPolicy">Privacy Policy</a></li>
								<li><a href="https://in.linkedin.com/company/iba-crafts-private-limited" target="_blank">In Media</a></li>
							</ul>
							</div>
						</div>
					</div>
					<div className="col-md-5">
						<div className="use_services footer-user-size service-footer-set" onClick={()=>this.toggleFilterBox('price')}>
							<h5 className="mb-4 footer-heading">SERVICES</h5><i className={this.state.showPriceFilterBox == 'show'? "fas fa-angle-down pull-right":"fas fa-angle-right pull-right"}></i>
							<div className={this.state.showPriceFilterBox == 'show'? "filter-sidepanel-container-active" : "filter-sidepanel-container" } >
							<p>Collaborative Designs Logistics Assistance</p>
							<p>Just In Time Manufacturing</p> 
							<p>Cataloging</p>
							<h5 style={{marginTop:'30px'}}><a href="/contact" target="_blank" title="Appointment">BOOK AN APPOINTMENT</a></h5>
							</div>
						</div>
					</div>
					<div className="col-md-4">
						<div className="use_addrs">
							<h5 className="mb-4 footer-heading vist-mob">VISIT US</h5>
						
							<h6>IBA CRAFTS PVT LTD</h6>
							<h6> <i>E Block, Sector 11, Noida</i></h6>
							<h6> <i>Uttar Pradesh 201301</i></h6>
							<h6 className="mt-4 footer-add">Call Us: <a  style={{color:'#fff'}} href="tel:0120-4111437"  target="_blank" title="Contact-Us">0120-4111437</a></h6>
							<h6 className="mt-4 footer-add footer-email">Email Us: <a style={{color:'#5a81b5'}} href="mailto:info@ibacrafts.com"  target="_blank" title="Mail">info@ibacrafts.com</a></h6>
							<div className="footer_log mt-4">
								<a href="/" className="logos" target="_blank" title="IBA">
									<img src="/img/ibalogo.JPG" alt="ibalogo"/>
									<span> IBA CRAFTS</span>
								</a>
							
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
    </div>
	  )
	  }
	  }
	  
	  
	  


 