import React, { Component } from "react";
import Header from './Header';
import Footer from './Footer';
import AboutBanner from '../common/AboutBanner';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export default class AboutUs extends Component{

render() {
	 
	  var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
	slidesToShow: window.innerWidth < 640 ? 1 : 3,
	adaptiveHeight:false,
	arrows:false,
	autoplay:true,
	autoplaySpeed:2000
  };
	  return(
	  
	<div className="AboutUs">
	 <Header act='about'/>
	 <AboutBanner/>

	 <section className="aboutgap glo">
			<div className="container">
				<div className="row">
					<div className="col-md-12">
					{/* <div className="main_contt_img">
								<img src="img/about_content_img.jpg" width="100%"/>
						</div> */}
						<h2 className="text-center mb-3">ABOUT IBA CRAFTS</h2>
						<p className="text-center">IBA CRAFT's the leading cross-border e-commerce company in India, manufacturing apparel and furnishing from India and selling them online globally. IBA has the distinction of having just developed groundbreaking technology for the apparel retail space known as Just in Time Garments Manufacturing (JITGM). No company in the world has developed this technology in their systems</p>
						<p className="text-center">JIT is revolutionizing the Garment Industry, addressing the biggest persistent industry problem of ‘Stocking / Inventory’ or warehousing and stock keeping. The technology uses 'demand-oriented production' to produce what is demanded by the market and thus has complete control of the utilization of resources with no wastage of fabric or garment. Moreover, there is no warehousing cost involved as Just In Time Garments Manufacturing is producing only those garments for which the order is received from the customer. IBA's JIT tech has eliminated the need for stock-keeping in all its product sales - Garments, POD fabric, Home Furnishing & Custom stationery & decorations.</p>
					</div><hr/>
					<div className="col-md-12">
					{/* <div className="main_contt_img">
								<img src="img/about_content_img.jpg" width="100%"/>
						</div> */}
						<h2 className="text-center mb-3">THE CURRENT MARKET  CHALLENGES</h2>
						<p className="text-center">IBA'S JIT addresses and solves some of the most significant and most troublesome issues facing the global apparel garment market today viz  </p>
					</div>
				</div>
			</div>
			<img src="img/about_round_img.jpg" width="100%"/>
		</section>

		<section id="aboutgap" className="aboutgap">
			<div className="container">
				<div className="row">
					<div className="col-md-12 mb-4"><hr/>
						<h2 className="text-center mb-3">
							HOW IBA'S JIT ADDRESSES AND SOLVES THE GLOBAL PROBLEMS PLAGUING THE TEXTILE INDUSTRY
						</h2>
						<p className="text-center">IBA's technology eliminates the need for warehousing & inventory management, ridding the need for large space, staff, power, service, security expenses. This means the elimination of waste, continuous improvement in productivity & reduced costs of production, as manufacturing of the garment commences only after the order is received from the customer. Other solutions of JIT are
						</p>
						<img src="img/aboutrectangle.jpg" width="100%"/>
					</div><hr/>
				</div>
			</div>
		</section>

		<section id="aboutgap" className="aboutgap  global-padding-cut">
			<div className="container">
				<div className="row">
					<div className="col-md-12 mb-4">
						<h2 className="text-center mb-3">
							HOW IBA TECHNOLOGY IS UNIQUE
						</h2>
						<p className="text-center">The fundamental challenge in the fashion industry is that companies lag customer’s expectations. This results in inventory pile-up and wastages. The consumers are no longer content with simply buying fashion products; the globalization era has empowered the consumers to look for fashion worldwide.
						</p>
						<p className="text-center">a)  IBACraft caters to the exact demand needs of consumers in this fast-moving fashion industry. IBACraft, catering to global consumers, has a library of apparels and garments from which a consumer can choose from an enormous variety - The consumers are not restricted by the limited inventory. The consumers want to interact, belong, influence, and be the brands from which they buy. IBACraft provides this personalization and influential experience through their online and offline experience stores.
						</p>
						<p className="text-center">b) Personalization - 75% of consumers prefer brands to personalize messaging, offers, and experiences, and 94% of companies see personalization as critical to current and future success.  IBACraft exactly caters to the mass personalization by leveraging augmented reality and experience stores.   
						</p>
						<p className="text-center">c) Situational Targeting - By tracking consumer behavior, either through online or through Experience Stores, IBACraft can target consumers based on consumption habits, behaviors, demography, and trends. The company can also predict the upcoming fashion trend the consumers are interested in since the company has a virtual library of unlimited apparel. Thus, catering to the demand as it originates.
						</p>
						<p className="text-center">d) Partnerships – IBA with their JIT technology can list their products on all marketplaces - BOB, TradeMe, Wish, Mercado, Lazada, Newegg, Catch, Price Minister, Cdiscount, Allegro, eBay and Amazon (as they do not need to hold inventory) - resulting in IBA's explosive growth.  
						</p>
						
					</div><hr/>
				</div>
			</div>
		</section>

		<section className="global_section global-padding-cut">
			<div className="container">
				<div className="row">
					<div className="col-md-12 mb-4">
						<h2 className="text-center mb-3">
							GLOBAL IMPACT
						</h2>
						<p className="text-center mb-4">The JIT Solution has the potential to change the way garment is being manufactured  today since textile is the most polluting category after oil globally
						</p>
					</div>
				</div>
			</div>
			<div className="global_dir">
				<div className="global_imp1">
					<div className="global_image">
						<img src="/img/global_icon_1.png"/>
					</div>
					<div className="global_contnt">
						<p>Clothing being produced only when required, thus saving textile waste and reducing the burden on environmental health hazards</p>
					</div>
				</div>
			</div>
			
			<div className="global_dir">
				<div className="global_imp2">
					<div className="global_image">
						<img src="/img/global_icon_2.png"/>
					</div>
					<div className="global_contnt">
						<p>Solving a passive pain point of the customer as water is saved as optimal resources are used</p>
					</div>
				</div>
			</div>
			<div className="global_dir">
				<div className="global_imp3">
					<div className="global_image">
						<img src="/img/global_icon_3.png"/>
					</div>
					<div className="global_contnt">
						<p>IBA's JIT has a positive effect on customers lives, and they contribute by buying products made in JIT Model #myfashionsaveswater</p>
					</div>
				</div>
			</div>
		</section>

		<section id="aboutgap" className="aboutgap mb-4">
			<div className="container">
				<div className="row">
					{/* <div className="col-md-12 mb-4"><hr/> */}
						<h2 className="text-center leader-head">
							OUR LEADERSHIP TEAM
						</h2>
					{/* </div> */}
					<div className="col-md-12 col-lg-4">
						<div className="img_sec">
							<img src="img/nitin_sir.png" width="100%"/>
						</div>
						<div className="img_contnt text-center mt-3">
							<h3>Nitin Kapoor</h3>
							<small>Co-founder & CEO</small>
							<p>Oversees the Finance, Marketing  & Technology. Responsible for growing the company. Previously Nitin managed  Investments for HNI's, Corporates  & Family offices at Kotak wealth  management & Standard  Chartered Bank </p>
						</div>
					</div>
					<div className="col-md-12 cool-lg-4  hema-box-size">
						<div className="img_sec">
							<img src="img/hemakappor.png" width="100%"/>
						</div>
						<div className="img_contnt text-center mt-3">
							<h3>Hema Kapoor</h3>
							<small>Co-founder & Creative Director </small>
							<p>Is the creative director of IBA and oversees the product development and its cataloging AR/VR for the frontend system. She strives her ways to solve the problem that the garment industry is facing, cut costs and improve sustainability through the Textech platform / Just In Time garments manufacturing.</p>
						</div>
					</div>
					<div className="col-md-12 col-lg-4">
						<div className="img_sec">
							<img src="img/amitgupta.png" width="100%"/>
						</div>
						<div className="img_contnt text-center mt-3">
							<h3>Amit Gupta</h3>
							<small>Co-founder & COO</small>
							<p>Extensive experience in garments manufacturing, having worked in a  family garments business followed by a long stint with eBay with detailed knowledge of fabric material & textiles.
							</p>
						</div>
					</div>
				</div>
			</div>
		</section>

		<section className="mb-5" style={{background:'#cccccca1'}}>
			<Slider {...settings}>
				  <div className="media_bott_slider">
					<img src="/img/companyimage/1.jpg" width="100%" />
				  </div>
				  {/* <div className="media_bott_slider">
					<img src="/img/companyimage/2.jpg" width="100%" />
				  </div> */}
				  <div className="media_bott_slider">
					<img src="/img/companyimage/3.jpg" width="100%" />
				  </div>
				  <div className="media_bott_slider">
					<img src="/img/companyimage/4.jpg" width="100%" />
				  </div>
				  <div className="media_bott_slider">
					<img src="/img/companyimage/5.jpg" width="100%" />
				  </div>
				  <div className="media_bott_slider">
					<img src="/img/companyimage/6.jpg" width="100%" />
				  </div>
				  <div className="media_bott_slider">
					<img src="/img/companyimage/7.jpg" width="100%" />
				  </div>
				  <div className="media_bott_slider">
					<img src="/img/companyimage/8.jpg" width="100%" />
				  </div>
				  <div className="media_bott_slider">
					<img src="/img/companyimage/9.jpg" width="100%" />
				  </div>
				  <div className="media_bott_slider">
					<img src="/img/companyimage/10.jpg" width="100%" />
				  </div>
				  <div className="media_bott_slider">
					<img src="/img/companyimage/11.jpg" width="100%" />
				  </div>
				  <div className="media_bott_slider">
					<img src="/img/companyimage/12.jpg" width="100%" />
				  </div>
				  <div className="media_bott_slider">
					<img src="/img/companyimage/13.jpg" width="100%" />
				  </div>
				  <div className="media_bott_slider">
					<img src="/img/companyimage/14.jpg" width="100%" />
				  </div>
				   <div className="media_bott_slider">
					<img src="/img/companyimage/15.jpg" width="100%" />
				  </div>
				  <div className="media_bott_slider">
					<img src="/img/companyimage/17.jpg" width="100%" />
				  </div>
				   <div className="media_bott_slider">
					<img src="/img/companyimage/18.jpg" width="100%" />
					</div>
				 <div className="media_bott_slider">
					<img src="/img/companyimage/16.jpg" width="100%" />
				 </div>
				  
			</Slider>
		</section>
		<Footer/>

    </div>
	  );
	  }
	  }
	  
	  
	  


 