import React, { Component } from "react";

export default class MediaBanner extends Component{

 render() {
	  return(
	<div className="MediaBanner">
	  <section id="MediaBanner" className="d-flex align-items-center scrolled-offset heros">
		<div className="container mt-5" data-aos="zoom-out" data-aos-delay="100">
		  <h1 className="mt-1">IN MEDIA</h1>
		  <h3>What they're saying about us</h3>
		</div>
	  </section>
    </div>
	  );
	  }
	  }
	  
	  
	  


 