import React, { Component } from 'react';
//import Menu from './Menu';
//import Category_content from './Category_content'; 
import Footer from './Footer'; 
import Header from './Header';
//import  './style1.css';
import Category_content from '../category_listing/Category_content'; 




export default class Category extends Component{
	
	constructor(props)
	{
         super(props);
	}
	

	render(){
        return(<div>
				<Header act="cat"/>
				<Category_content/>
				<Footer/>
				
			</div>);		
    }
}
 
 