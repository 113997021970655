import React, { Component } from 'react';
import { BrowserRouter as Router,Switch, Route, Link } from "react-router-dom";
import Home from './components/pages/Home';
import HomeBanner from './components/common/HomeBanner';
import SiteService from './components/pages/SiteService';
import SampleKit from './components/pages/SampleKit';
import Contact from './components/pages/contact';
import Category from './components/pages/Category';
import AboutUs from './components/pages/AboutUs';
import Media from './components/pages/Media';
import PartnerLogin from './components/pages/PartnerLogin';

 
const ComponentRoute=()=>( 
	<Router>
			<Switch>
											 
				<Route exact path='/' component={Home} />							 
				<Route exact path='/HomeBanner' component={HomeBanner} />
				<Route exact path='/SiteService' component={SiteService} />					
				<Route exact path='/SampleKit' component={SampleKit} />
				<Route exact path='/contact' component={Contact} />
				<Route exact path='/catalog' component={Category} />
				<Route exact path='/AboutUs' component={AboutUs} />
				<Route exact path='/Media' component={Media} />
				<Route exact path='/Login' component={PartnerLogin} />
			</Switch>
			</Router>
		);
export default ComponentRoute;

