import React, { Component } from "react";

export default class Header extends Component{
constructor(props){
         super(props);	
		
			this.state = {
				
				open:false,
				close:true
				
						 }
				 
	         this.headerMenu=this.headerMenu.bind(this);     
	         this.headerClose=this.headerClose.bind(this);     
	}
	headerMenu(){
		
		this.setState({open:true,close:false},function(){
		
		})
	}
	headerClose(){
		
		this.setState({open:false,close:true},function(){
			
		})
	}
 render() {
	console.log(this.props.pageActive);
	  return(
		 <div className="Header">
			  <header id="header" className="d-flex align-items-center fixed-top">
			<div className="container d-flex align-items-center justify-content-between">
			 <a href="/" className="logo"><img src="/img/ibalogo.JPG" alt=""/><span> IBA CRAFTS</span></a>
			  <nav id="navbar" className={this.state.open?"navbar-mobile":"navbar"}>
				<ul>
				  <li><a className={this.props.act==="home"?"nav-link scrollto active":"nav-link scrollto"} href="/">Home</a></li>
				  <li><a className={this.props.act==="site"?"nav-link scrollto active":"nav-link scrollto"} href="/SiteService">Services</a></li>
				  <li><a className={this.props.act==="cat"?"nav-link scrollto active":"nav-link scrollto"} href="/catalog">Catalog</a></li>
				   <li><a className={this.props.act==="about"?"nav-link scrollto active":"nav-link scrollto"} href="/aboutus">About</a></li>
				  <li><a className={this.props.act==="contact"?"nav-link scrollto active":"nav-link scrollto"} href="/contact">Contact</a></li>
				  <li><a className={this.props.act==="media"?"nav-link scrollto active":"nav-link scrollto"} href="/media">Media</a></li>
				  <li><a className={this.props.act==="partner"?"nav-link scrollto active":"nav-link scrollto"} href="http://partner.ibacrafts.com">Partner Login</a></li>
				</ul>
				{this.state.close ? <div  className="bi bi-list mobile-nav-toggle" onClick={this.headerMenu}></div>:
				<div  className="bi bi-list mobile-nav-toggle sau" onClick={this.headerClose}></div>}
				
			  </nav>
			</div>
		  </header>

    </div>
	  )
	  }
	  }
	  
	  
	  


 