import React,{Component} from 'react';
import Modal from 'react-responsive-modal'; 
import ReactImageZoom from 'react-image-zoom';

//const imageUrl = 'https://s4sassy-ibacraftspvtltd.netdna-ssl.com/inkmesoimages/';
// const imageUrl = 'https://s4sassy.com/inkmesoimages/';
// const largeImageUrl = 'https://s4sassy-ibacraftspvtltd.netdna-ssl.com/ibaweb/pub/media/catalog/product';
const imageUrl = 'https://bubsbliss.com/inkmesoimages/';
const largeImageUrl = 'https://bubsbliss.com/ibaweb/pub/media/catalog/product';
//const images =[{ src: 'https://s4sassy-ibacraftspvtltd.netdna-ssl.com/inkmesoimages/_ffffff-902576-min-fl-263i_422_1_amz.jpg', title: 'title', content: 'content' }];

export default class Slider_2 extends Component {
	constructor(props){
         super(props);
		 this.state = {
					open:false,
					productViewMainImage: '',
					preLoder: window.location.protocol + '//' +  window.location.host +"/images/preloader.gif",
					productMainImage : "",
					zoomWidth:350,
			};	
	}
	
	componentDidUpdate(prevProps, prevState) {
		if(prevProps.fImage!=this.props.fImage){
			this.setState({productMainImage: imageUrl+this.props.sliderData[0].file.split('/').pop()});
		}
				
		setTimeout(function(){
			window.set_product_view_slider();
		},
		10
		);	
	}
	
	componentDidMount(){
		this.setState({productMainImage: imageUrl+this.props.sliderData[0].file.split('/').pop()});
	}
	
	onOpenModal= (index)=>{	
		 
		this.setState({productViewMainImage: largeImageUrl+this.props.sliderData[0].file})		
		this.setState({ open: true });	
	};
 
	onCloseModal = () => {
		this.setState({ open: false });
	};
	
	handelThumbnailClick(file){
		this.setState({productViewMainImage: ''},function(){
			this.setState({productViewMainImage: largeImageUrl+file});
		});
	}
	
	handelProductThumbnailHover = (src) =>{
		this.setState({productMainImage: src},function(){
			
		});
	}
	
	
	render() {
    return (<div className="tovar_view_fotos clearfix" style={{width:'42%'}}>
				<div className="slider-box">
				<div id="slider2-2" className="">	
					<ul className="slides">						
												 
							<div className="fluid">
								<div className="fluid__image-container" style={{background:'url('+this.state.preLoder+')'}}> 
									 
									 {/*<div className="product-image-box" 
										style={{backgroundImage:'url('+imageUrl+list.file.split("/").pop()+')',filter:'contrast(1.2)'}} 
										onClick={() => this.onOpenModal()} >
									 </div>*/}
									
									<div className="product-image-box-2" onClick={() => this.onOpenModal()} >
										{this.state.productMainImage!='' &&
										<ReactImageZoom {...{
											zoomWidth: this.state.zoomWidth, 
											img: this.state.productMainImage,
											zoomLensStyle:'opacity: 0.7;background-color: #fff;',
											zoomPosition:'right'
											}} />
										}
									</div>
										
									<p style={{'text-align':'center'}}>Click on image to view in HD qality</p>
								</div>
							</div>
					</ul>
					
				</div>
				<div id="carousel2-2" className="flexslider">
					<ul className="slides">
						{this.props.sliderData.map((list,i)=> 
							<li 
								style={{backgroundImage:'url('+imageUrl+list.file.split("/").pop()+')',filter:'contrast(1.1)'}} 
								onMouseEnter={()=>this.handelProductThumbnailHover(imageUrl+list.file.split("/").pop())}
								onClick={()=>this.handelProductThumbnailHover(imageUrl+list.file.split("/").pop())}
							></li>)}						
					</ul>
				</div>
				
				</div>
				
				<Modal open={this.state.open}  onClose={this.onCloseModal} >
					<div id="product-image-view" className="product-image-view" style={{backgroundImage:'url('+this.state.preLoder+')'}}>
					{this.state.productViewMainImage &&					
						<img src={this.state.productViewMainImage}/>
					}
					
					</div> 
					
					<div className="product-image-view-thumbnail">
						<ul>
							{this.props.sliderData.map((list,i)=> 
								<li 
									data-url={largeImageUrl+list.file}
									style={{backgroundImage:'url('+imageUrl+list.file.split("/").pop()+')',filter:'contrast(1.1)'}} 
									onClick={(e) => this.handelThumbnailClick(list.file)}>
									&nbsp;
								</li>
							)}	
						</ul>	
					 </div>
				</Modal>
			</div>) 	
  }
}