import React,{PureComponent} from 'react';
import Popup from "reactjs-popup";
import { BrowserRouter as Router,Switch, Route, Link } from "react-router-dom";
import Modal from 'react-responsive-modal';
import 'react-responsive-modal/styles.css';
import Quick_view from '../product_view/Quick_view'; 
//import  '../pages/style1.css';
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';
//import Pagination from 'react-bootstrap-4-pagination';

const baseIpUrl = 'http://52.8.10.81:5000/';
//const basePUrl = 'http://52.8.10.81:3000/';
const basePUrl = window.location.protocol + '//' +  window.location.host +"/";
 
//imageBaseUrl:'http://52.53.235.147/pub/media/catalog/product/';

var customStyles = {
						overlay : {
							"z-Index": 99999
						  } 
						};
						
export default class Shop_block extends PureComponent {
	
	constructor(props){ 
         super(props);
		 this.state = {
				//imageBaseUrl:'https://s4sassy-ibacraftspvtltd.netdna-ssl.com/ibaweb/pub/media/catalog/product/',
				//imageBaseUrl:'https://s4sassy-ibacraftspvtltd.netdna-ssl.com/inkmesoimages/',
				//imageBaseUrl:'https://s4sassy.com/inkmesoimages/',
				imageBaseUrl:'https://bubsbliss.com/inkmesoimages/',
				open: false,
				popUpImage:[],
				pNameInPopUp:'',
				priceInPopUp:'',
				listImagesInPopUp:[],
				productData:[],
				sizeOptions:[],
				colorOptions:[],
				defaultProChildKey:'',
				color : '',
				size : '',
				minprice:'',
				maxprice:'',
				brand:'Inkmeso',
				addToCartButtonDisable:'',
				addToCartButtonStyle:'',
				outOfStock :'',
				productImages:[],
				productSku:'',
				productId:''
			};
	}
	
	
	componentDidUpdate(prevProps, prevState) {
	  setTimeout(function(){
			//window.set_product_view_slider();
		},
		10
		);	
	}
	
	

	onOpenModal= (index)=>{	
		this.setState({'productSku': this.props.productlist[index].parent_sku},function(){
			this.setState({'productId': this.props.productlist[index].entity_id},function(){
				this.setState({ open: true });
			});		
		});		
	};
 
	onCloseModal = () => {
		this.setState({ open: false });
	};
	
	getColor(keyOuter){
		return Object.keys(this.props.productlist[keyOuter].color_value.split(",")).slice(0,5).map((color)=>{
			return(
	               	<li>
						<a href="javascript:void(0);" 
						onClick={() => this.onOpenModal(keyOuter)} 
						title={this.props.productlist[keyOuter].color_value.split(",")[color]} style={{background: this.props.productlist[keyOuter].color_value.split(",")[color]}}></a>
				    </li>	
			)
		})
	}
	
    getSize(keyOuter){
			return(<li style={{border:'none'}}>
		<a href="javascript:void(0);"
		onClick={() =>this.props.productlist[keyOuter].color_value.split(",").slice(5).length>0 ? 
		this.onOpenModal(keyOuter):"javascript:void(0);"}>
			<u>
				{this.props.productlist[keyOuter].color_value.split(",").slice(5).length>0?
				"+"+this.props.productlist[keyOuter].color_value.split(",").slice(5).length:''}
			</u>
			</a>
		</li>
			
			)
	}
	
	getProducts(){
		if(this.props.productlist.length > 0){
			return Object.keys(this.props.productlist).map(keyOuter => {
					let product_url = '';
					if(this.props.isFromFilterd){
						product_url = basePUrl+"product/"+this.props.catName+"/"+this.props.productlist[keyOuter].entity_id+"?cid="+this.props.catId; 
					}else if(this.props.isFromSearch && !this.props.isFromFilterd){
						let cat_id = this.props.productlist[keyOuter].category;
						if(typeof cat_id !== 'number'){
							cat_id = this.props.productlist[keyOuter].category.split(',').pop();
						}
						product_url = basePUrl+"product/"+this.props.catName+"/"+this.props.productlist[keyOuter].entity_id+"/"+this.props.productlist[keyOuter].parent_sku+"?cid="+cat_id 
					}else{
						product_url = basePUrl+"product/"+this.props.catName+"/"+this.props.productlist[keyOuter].entity_id+"/"+this.props.productlist[keyOuter].parent_sku+"?cid="+this.props.catId;
					}

					return (<div className="tovar_wrapper col-lg-3 col-md-3 col-sm-6 col-xs-6 col-ss-12 padbot40" style={{height:'400px'}}>  
								<div className="tovar_item clearfix">
											<div className="tovar_img">
											<a className="add_lovelist" href="javascript:void(0);" onClick={() => this.onOpenModal(keyOuter)}>
											 
														<div className="tover-image-overlay">&nbsp;</div>
											</a> 
													
													<div className="tovar_img_wrapper" style={{ backgroundImage: 'url('+this.state.imageBaseUrl+this.props.productlist[keyOuter].main_image_url.split("/").pop()+')'}}>
								                       
														{/* <!--<img className="img" src={this.state.imageBaseUrl+this.props.productlist[keyOuter][keyInner].media_gallery_entries[0].file.split("/").pop()} alt="" />--> */}
														
														{/* <!--<img className="img_h" src={this.state.imageBaseUrl+this.props.productlist[keyOuter][keyInner].media_gallery_entries[0].file.split("/").pop()} alt="" />--> */}
													</div>
												
												
												{/* <div className="tovar_item_btns">
													<div className="open-project-link">
														<a className="add_lovelist" href="javascript:void(0);" onClick={() => this.onOpenModal(keyOuter)}>
														<i className="fa fa-search-plus"></i>
														</a>														
													</div>
													<a className="add_lovelist" href="javascript:void(0);" onClick={() => this.onOpenModal(keyOuter)} ><i className="fa fa-shopping-cart"></i></a>
													<a className="add_lovelist" href="javascript:void(0);" onClick={() => this.onOpenModal(keyOuter)} ><i className="fa fa-heart"></i></a>
														
												</div> */}
											</div>
											<div className="tovar_description clearfix sidepanel widget_color" >
												<div className="filter-sidepanel-container-active" style={{display: 'block'}}>
												<ul>
													{this.getColor(keyOuter)}
													{this.getSize(keyOuter)}
												</ul>
												
												</div>
												<h4 className="brand_name margbot0">
												{this.props.productlist[keyOuter].brand_name!='' ? this.props.productlist[keyOuter].brand_name : 'Inkmeso'} 
												</h4>
												<span className="tovar_title" style={{fontSize:'10px'}}>
													{this.props.productlist[keyOuter].name.substring(0, 100).replace(/[^a-z0-9 -]/gi,' ')}
												</span>
												{/* <span className="tovar_price" style={{fontSize:'14px'}} >${this.props.productlist[keyOuter].min_price}</span> */}
												</div>
											
											<div className="tovar_content">What makes our cashmere so special? We start with the finest yarns from an Italian mill known for producing some of the softest cashmere out there.</div>
										</div>
					</div>)	
				})	
			}else{
				
				let currntPage=window.location.href;
				if(currntPage.includes("&page=")){
					//currntPage.split("&page=")[0];
					window.location.replace(currntPage.split("&page=")[0]);
				}
				else{
					return <div style={{textAlign:'center'}}>
							<img src="/images/nodata.jpg"/>
						</div>;
				}
				
				
				
				
			}		
				
	}
	
	
	
	
	
	
	
	
	
	
	getFooterBanner(){
		return(<div className="row top_sale_banners center">
				{/* <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4 col-ss-12"><a className="banner nobord margbot30" href="javascript:void(0);" ><img src="/images/tovar/banner22.jpg" alt="" /></a></div>
				<div className="col-lg-8 col-md-8 col-sm-8 col-xs-8 col-ss-12"><a className="banner nobord margbot30" href="javascript:void(0);" ><img src="/images/tovar/banner23.jpg" alt="" /></a></div> */}
				</div>);
	}
	
	render() {	
		return (<div>
				{/* this.getShowBreadCrumb() */}
				{/*this.getSortingOptions()*/}
				
				<div className="row shop_block">
				
					{this.props.productlist && this.getProducts() }
					
					<div id="quick_view_modal">
						<Modal open={this.state.open}  onClose={this.onCloseModal} style={customStyles} >
							<Quick_view sku={this.state.productSku} pid={this.state.productId} cid={this.props.catId} category={this.props.catName}/>	
						</Modal>
					</div>
				</div>
				
				<hr></hr>
					
					{this.getFooterBanner()}
			</div>);		
	}
}