import React, { Component } from 'react';
import Header from './Header';
import Footer from './Footer';
import MediaBanner from '../common/MediaBanner';
import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal';
export default class Media extends Component{
	
	constructor(props)
	{
         super(props);
		 
		 
		 this.state={
			onOpenModaluser:false,
			currentImage:'',
        }
	}
	


    onOpenModaluser = (currentImage) => { 
		this.setState({ onOpenModaluser: true,currentImage: currentImage}); 
	}
  
	onCloseModal1user = (event) => {
         this.setState({ onOpenModaluser: false});
	};
  


	render(){
        return(<div>
				<Header />
				<MediaBanner/>
				<section className="matter-mon-pad">
					<div className="container">
						<div className="row">
							<div className="col-md-12 col-lg-12">
								<div className="site_top_contents">
									<p className="text-center">In a matter of just 24 months and 1 Million USD of personal investment, IBA cracked the JIT model in Garments Manufacturing and home-furnishing industry. The process was unique, which they later got copyrighted and has the first-mover advantage. In a highly competitive market scenario like the current times, the magic wand that kept IBA ahead of all in the race is JIT innovation, which is based on the Augmented Reality concept.” – Hindustan Times
									</p>
								</div>
							</div>
						</div>
					</div>
				 </section>
				 
				 <section className="mt-5 media-picture-mar">
					<div className="container-fluid custom_container">
						<div className="row">
						<hr className="horizo"/>
							
							{/* 1popup section start */}
							<div className="col-lg-3 col-md-6 col-sm-6">
								<div className="media_image"  onClick={()=>this.onOpenModaluser('1.jpg')}>
									<div className="medialogo">
										<img src="img/media_img/logo/the_hindu.png" width="150px"/>
									</div>
									<div className="newsimg newsimgs">
										<a href ="#">
										<img src="img/media_img/popup/1.jpg" width="100%"/>
										</a>
									</div>
								</div>
							</div>
							{/* popup section end */}
							
							{/* popup section start */}
							<div className="col-lg-3 col-md-6 col-sm-6">
								<div className="media_image"  onClick={()=>this.onOpenModaluser('yahoo.jpg')}>
									<div className="medialogo">
										<img src="img/media_img/logo/yahoo.png" width="150px"/>
									</div>
									<div className="newsimg newsimgs">
										<a href ="#">
										   <img src="img/media_img/popup/Yahoo-small.jpg" width="100%"/>
										</a>
									</div>
								</div>
							</div>
							{/* popup section end */}
							
							{/* popup section start */}
							<div className="col-lg-3 col-md-6 col-sm-6">
								<div className="media_image"  onClick={()=>this.onOpenModaluser('2.png')}>
									<div className="medialogo">
										<img src="img/media_img/logo/the_asian_age.png" width="150px"/>
									</div>
									<div className="newsimg newsimgs">
										<a href ="#">
											<img src="img/media_img/popup/2.png" width="100%"/>	
										</a>
									</div>
								</div>
							</div>
							{/* popup section end */}
							
							{/* popup section start */}
							<div className="col-lg-3 col-md-6 col-sm-6">
								<div className="media_image"  onClick={()=>this.onOpenModaluser('3.jpg')}>
									<div className="medialogo">
										<img src="img/media_img/logo/financial express.png" width="150px"/>
									</div>
									<div className="newsimg newsimgs">
										<a href="#"><img src="img/media_img/popup/3.jpg" width="100%"/>
										</a>
									</div>
								</div>
							</div>
							{/* popup section end */}
							
							{/* popup section start */}
							<div className="col-lg-3 col-md-6 col-sm-6">
								<div className="media_image"  onClick={()=>this.onOpenModaluser('4.jpg')}>
									<div className="medialogo">
										<img src="img/media_img/logo/the_hindu.png" width="150px"/>
									</div>
									<div className="newsimg newsimgs">
										<a href ="#">
										<img src="img/media_img/popup/4.jpg" width="100%"/>	
										</a>
									</div>
								</div>
							</div>
							{/* popup section end */}
							
							{/* popup section start */}
							<div className="col-lg-3 col-md-6 col-sm-6">
								<div className="media_image"  onClick={()=>this.onOpenModaluser('5.png')}>
									<div className="medialogo">
										<img src="img/media_img/logo/Business_standard.png" width="150px"/>
									</div>
									<div className="newsimg newsimgs">
										<a href ="#"><img src="img/media_img/popup/5.png" width="100%"/>
										</a>
									</div>
								</div>
							</div>
							{/* popup section end */}
							
							{/* popup section start */}
							<div className="col-lg-3 col-md-6 col-sm-6">
								<div className="media_image"  onClick={()=>this.onOpenModaluser('6.jpg')}>
									
									<div className="medialogo">
									<img src="img/media_img/logo/thenew_indianexpress_red.png" width="150px"/>
										</div>
									
									<div className="newsimg newsimgs">
										<a href ="#">
											<img src="img/media_img/popup/6.jpg" width="100%"/>
										</a>
									</div>
								</div>
							</div>
							{/* popup section end */}
							
							{/* popup section start */}
							<div className="col-lg-3 col-md-6 col-sm-6">
								<div className="media_image"  onClick={()=>this.onOpenModaluser('7.jpg')}>
									<div className="medialogo">
										<img src="img/media_img/logo/thenew_indianexpress_red.png" width="150px"/>
									</div>
									<div className="newsimg newsimgs">
										<a href ="#"><img src="img/media_img/popup/7.jpg" width="100%"/>
										</a>
									</div>
								</div>
							</div>
							{/* popup section end */}
							
							{/* popup section start */}
							<div className="col-lg-3 col-md-6 col-sm-6">
								<div className="media_image"  onClick={()=>this.onOpenModaluser('8.jpg')}>
									<div className="medialogo">
									<img src="img/media_img/logo/thenew_indianexpress_red.png" width="150px"/>
									</div>
									<div className="newsimg newsimgs">
										<a href ="#">
										<img src="img/media_img/popup/8.jpg" width="100%"/>
										</a>
									</div>
								</div>
							</div>
							{/* popup section end */}
							
							{/* popup section start */}
							<div className="col-lg-3 col-md-6 col-sm-6">
								<div className="media_image"  onClick={()=>this.onOpenModaluser('9.png')}>
									<div className="medialogo">
									<img src="img/media_img/logo/sme_world.png" width="150px"/>
									</div>
									<div className="newsimg newsimgs">
										<a href ="#"><img src="img/media_img/popup/9.png" width="100%"/>
										</a>
									</div>
								</div>
							</div>
							{/* popup section end */}
							
							{/* popup section start */}
							<div className="col-lg-3 col-md-6 col-sm-6">
								<div className="media_image"  onClick={()=>this.onOpenModaluser('11.png')}>
									<div className="medialogo">
									<img src="/img/media_img/logo/deccan_herald.png" width="150px"/>
									</div>
									<div className="newsimg newsimgs">
										<a href ="#"><img src="img/media_img/popup/11.png" width="100%"/></a>	
									</div>
								</div>
							</div>
							{/* popup section end */}
							
							{/* popup section start */}
							<div className="col-lg-3 col-md-6 col-sm-6">
								<div className="media_image"  onClick={()=>this.onOpenModaluser('12.jpg')}>
									<div className="medialogo">
									<img src="img/media_img/logo/morning_standard.png" width="150px"/>
									</div>
									<div className="newsimg newsimgs">
										<a href ="#"><img src="img/media_img/popup/12.jpg" width="100%"/></a>
									</div>
								</div>
							</div>
							{/* popup section end */}
							
							{/* popup section start */}
							<div className="col-lg-3 col-md-6 col-sm-6">
								<div className="media_image"  onClick={()=>this.onOpenModaluser('13.jpg')}>
									<div className="medialogo">
									<img src="img/media_img/logo/the-times-of-india.png" width="150px"/>
									</div>
									<div className="newsimg newsimgs">
										<a href ="#"><img src="img/media_img/popup/13.jpg" width="100%"/></a>
									</div>
								</div>
							</div>
							{/* popup section end */}
							
							{/* popup section start */}
							<div className="col-lg-3 col-md-6 col-sm-6">
								<div className="media_image"  onClick={()=>this.onOpenModaluser('14.jpg')}>
									<div className="medialogo">
										<img src="img/media_img/logo/the_sunday_guardian.png" width="150px"/>
									</div>
									<div className="newsimg newsimgs">
										<a href ="#"><img src="img/media_img/popup/14.jpg" width="100%"/></a>
									</div>
								</div>
							</div>
							{/* popup section end */}
							
							{/* popup section start */}
							<div className="col-lg-3 col-md-6 col-sm-6">
								<div className="media_image"  onClick={()=>this.onOpenModaluser('iba-opens-door.jpg')}>
									<div className="medialogo">
										<img src="img/media_img/logo/the-news-of-india.png" width="150px"/>
									</div>
									<div className="newsimg newsimgs">
										<a href="#"><img src="img/media_img/svg/1.svg" width="100%"/></a>
									</div>
								</div>
							</div>
							{/* popup section end */}
							
							{/* popup section start */}
							<div className="col-lg-3 col-md-6 col-sm-6">
								<div className="media_image"  onClick={()=>this.onOpenModaluser('jit2.jpg')}>
									<div className="medialogo">
										<img src="img/media_img/logo/financial express.png" width="150px"/>
									</div>
									<div className="newsimg newsimgs">
										<a href ="#"><img src="img/media_img/svg/2.svg" width="100%"/></a>
									</div>
								</div>
							</div>
							{/* popup section end */}
							
							{/* popup section start */}
							<div className="col-lg-3 col-md-6 col-sm-6">
								<div className="media_image"  onClick={()=>this.onOpenModaluser('just-in-time.jpg')}>
									<div className="medialogo">
										<img src="img/media_img/logo/smart_water_and_waste.png" width="150px"/>
									</div>
									<div className="newsimg newsimgs">
										<a href ="#"><img src="img/media_img/svg/3.svg" width="100%"/></a>
									</div>
								</div>
							</div>
							{/* popup section end */}
							
							
							{/* popup section start */}
							<div className="col-lg-3 col-md-6 col-sm-6">
								<div className="media_image"  onClick={()=>this.onOpenModaluser('mumbai2.jpg')}>
									<div className="medialogo">
										<img src="img/media_img/logo/financial express.png" width="150px"/>
									</div>
									<div className="newsimg newsimgs">
										<a href ="#"><img src="img/media_img/svg/4.svg" width="100%"/></a>
									</div>
								</div>
							</div>
							{/* popup section end */}
							
							{/* popup section start */}
							<div className="col-lg-3 col-md-6 col-sm-6">
								<div className="media_image"  onClick={()=>this.onOpenModaluser('PTI.jpg')}>
									<div className="medialogo">
										<img src="img/media_img/logo/press_trust_india.png" width="150px"/>
									</div>
									<div className="newsimg newsimgs">
										<a href ="#"><img src="img/media_img/svg/5.svg" width="100%"/></a>
									</div>
								</div>
							</div>
							{/* popup section end */}
							
							<div className="col-lg-3 col-md-6 col-sm-6">
								<div className="media_image">
									
									<div className="medialogo">
										<img src="img/media_img/logo/fibre2fashion.png" width="150px"/>
									</div>
									
									<div className="newsimg newsimgs">
										<a href="https://www.fibre2fashion.com/interviews/face2face/iba-craft/nitin-kapoor/12647-1/" target="_blank"><img src="img/media_img/1/Fashion.JPG" width="100%"/></a>
									</div>
								</div>
							</div>
							
							<div className="col-lg-3 col-md-6 col-sm-6">
							<div className="media_image">
																
									<div className="medialogo">
										<img src="img/media_img/logo/CNBC-Logo-Square.png" width="150px"/>
									</div>
									
									<div className="newsimg newsimgs">
										<a href="https://www.youtube.com/watch?v=D8zq6dcxHnE" target="_blank"><img src="img/media_img/popup/CNBC.png" width="100%"/></a>
									</div>
								</div>
							</div>
							
							<div className="col-lg-3 col-md-6 col-sm-6">
								<div className="media_image">
									
									<div className="medialogo">
										<img src="img/media_img/logo/bloomberg-news.png" width="150px"/>
									</div>
									
									<div className="newsimg newsimgs">
										<a href="https://www.youtube.com/watch?v=LPqb1Fjj45Y" target="_blank">
										<img src="img/media_img/popup/Bloomberg.png" width="100%"/></a>
									</div>
								</div>
							</div>
							
							<div className="col-lg-3 col-md-6 col-sm-6">
								<div className="media_image">
									<div className="medialogo">
										<img src="img/media_img/logo/outlook media.png" width="150px" />
									</div>
									<div className="newsimg newsimgs">
										<a href="https://www.outlookindia.com/newsscroll/jit-innovation-by-an-indian-entrepreneur-revolutionizes-the-textile-and-fashion-industry/1958005" target="_blank">
										<img src="img/media_img/2/2.JPG" width="100%"/></a>
									</div>
								</div>
							</div>
							
							<div className="col-lg-3 col-md-6 col-sm-6">
								<div className="media_image">
									<div className="medialogo">
										<img src="img/media_img/logo/yourStory.PNG" width="150px"/>
									</div>
									<div className="newsimg newsimgs">
										<a href="https://yourstory.com/smbstory/noida-entrepreneur-ecommerce-save-water-online-shopping-fashion-clothing-jewellery-home-decor/amp" target="_blank">
										<img src="img/media_img/yourStory.PNG" width="100%"/></a>
									</div>
								</div>
							</div>
							
							
							<div className="col-lg-3 col-md-6 col-sm-6">
								<div className="media_image">
									<div className="medialogo">
										<img src="img/media_img/logo/the_week.png" target="_blank" width="150px"/>
									</div>
									<div className="newsimg newsimgs">
										<a href="https://www.theweek.in/wire-updates/business/2020/10/19/pwr7-jit--innovation.html" target="_blank"><img src="img/media_img/3/3.JPG" width="100%"/></a>
									</div>
								</div>
							</div>
							<div className="col-lg-3 col-md-6 col-sm-6">
								<div className="media_image">
									<div className="medialogo">
										<img src="img/media_img/logo/REPUBLIC.png" width="150px"/>
									</div>
									<div className="newsimg newsimgs">
										<a href="https://www.republicworld.com/business-news/india-business/jit-innovation-by-an-indian-entrepreneur-revolutionizes-the-textile-and-fashion-industry.html" target="_blank"><img src="img/media_img/4/33.JPG" width="100%"/></a>
									</div>
								</div>
							</div>
							<div className="col-lg-3 col-md-6 col-sm-6">
								<div className="media_image">
									<div className="medialogo">
										<img src="img/media_img/logo/thenew_indianexpress_red.png" width="150px" />
									</div>
									<div className="newsimg newsimgs">
										<a href="https://www.newindianexpress.com/lifestyle/fashion/2020/oct/05/zero-waste-fashion-just-got-quicker-2205823.html" target="_blank"><img src="img/media_img/5/5.jpg" width="100%" target="_blank"/></a>
									</div>
								</div>
							</div>
							<div className="col-lg-3 col-md-6 col-sm-6">
								<div className="media_image">
									<div className="medialogo">
										<img src="img/media_img/logo/project hatch.png" width="150px" target="_blank"/>
									</div>
									<div className="newsimg newsimgs">
										<a href="https://www.projecthatch.co/interview/developing-a-technology-for-on-demand-garment-manufacturing-industry/" target="_blank"><img src="img/media_img/12/Capture.JPG" width="100%" target="_blank"/></a>
									</div>
								</div>
							</div>
							<div className="col-lg-3 col-md-6 col-sm-6">
								<div className="media_image">
									<div className="medialogo">
										<img src="img/media_img/logo/smart_water_and_waste.png" width="150px" />
									</div>
									<div className="newsimg newsimgs">
										<a href="https://smartwww.in/just-in-time-garments-manufacturing-a-disruptive-tex-tech/" target="_blank"><img src="img/media_img/12/Capture.JPG" width="100%" target="_blank"/></a>
									</div>
								</div>
							</div>
							<div className="col-lg-3 col-md-6 col-sm-6">
								<div className="media_image">
									<div className="medialogo">
										<img src="img/media_img/logo/thehindu_business_line.png" width="150px"/>
									</div>
									<div className="newsimg newsimgs">
										<a href="https://www.thehindubusinessline.com/todays-paper/tp-cleantech/article31036559.ece#" target="_blank"><img src="img/media_img/8/88.JPG" width="100%" target="_blank" /></a>
									</div>
								</div>
							</div>
							<div className="col-lg-3 col-md-6 col-sm-6">
								<div className="media_image">
									<div className="medialogo">
										<img src="img/media_img/logo/deccan_herald.png" width="150px"/>
									</div>
									<div className="newsimg newsimgs">
										<a href="https://www.deccanherald.com/business/budget-2020/the-budget-2020-should-concentrate-and-inspire-entrepreneurs-to-ideate-and-implement-efficient-methodologies-798642.html" target="_blank"><img src="img/media_img/9/Capture.JPG" width="100%"/></a>
									</div>
								</div>
							</div>
							<div className="col-lg-3 col-md-6 col-sm-6">
								<div className="media_image">
									<div className="medialogo">
										<img src="img/media_img/logo/thenew_indianexpress_red.png" width="150px"/>
									</div>
									<div className="newsimg newsimgs">
										<a href="https://www.newindianexpress.com/lifestyle/fashion/2020/jan/25/putting-tech-in-textiles-2094322.html" target="_blank"><img src="img/media_img/10/Capture.JPG" width="100%"/></a>
									</div>
								</div>
							</div>
							<div className="col-lg-3 col-md-6 col-sm-6">
								<div className="media_image">
									<div className="medialogo">
										<img src="img/media_img/logo/txtile_value_chain.png" width="150px" />
									</div>
									<div className="newsimg newsimgs">
									<a href="https://textilevaluechain.in/news-insights/jit-an-innovation-from-indian-entrepreneur-to-revolutionise-the-global-textile-fashion-industry/"target="_blank"><img src="img/media_img/11/111.JPG" width="100%"/></a>
									</div>
								</div>
							</div>
							<div className="col-lg-3 col-md-6 col-sm-6">
								<div className="media_image">
									<div className="medialogo">
										<img src="img/media_img/logo/the_sunday_guardian.png" width="150px"/>
									</div>
									<div className="newsimg newsimgs">
										<a href="https://www.sundayguardianlive.com/culture/now-ship-products-10-international-markets" target="_blank" ><img src="img/media_img/12/Capture.JPG" width="100%"/></a>
									</div>
								</div>
							</div>
							<div className="col-lg-3 col-md-6 col-sm-6">
								<div className="media_image">
									<div className="medialogo">
										<img src="img/media_img/logo/financial express.png" width="150px"/>
									</div>
									<div className="newsimg newsimgs">
										<a href="https://www.financialexpress.com/industry/technology/tech-tailor-how-ar-vr-will-impact-the-fashion-industry/1755579/" target="_blank"><img src="img/media_img/13/Capture.JPG" width="100%"/></a>
									</div>
								</div>
							</div>
							<div className="col-lg-3 col-md-6 col-sm-6">
								<div className="media_image">
									<div className="medialogo">
										<img src="img/media_img/logo/the-news-of-india.png" width="150px"/>
									</div>
									<div className="newsimg newsimgs">
										<a href="http://www.uniindia.com/iba-opens-doors-to-green-technology-in-industrial-space/india/news/1751535.html" target="_blank"><img src="img/media_img/14/Capture.JPG" width="100%"/></a>
									</div>
								</div>
							</div>
							<div className="col-lg-3 col-md-6 col-sm-6">
								<div className="media_image">
									<div className="medialogo">
										<img src="img/media_img/logo/Rretailer.png" width="150px"/>
									</div>
									<div className="newsimg newsimgs">
										<a href="https://www.indianretailer.com/interview/retail-people/startup/How-IBA-a-fashion-start-up-is-using-technology-to-promote-CBT-trade.i1651/" target="_blank"><img src="img/media_img/15/Capture.JPG" width="100%"/></a>
									</div>
								</div>
							</div>
							<div className="col-lg-3 col-md-6 col-sm-6">
								<div className="media_image">
									<div className="medialogo">
										<img src="img/media_img/logo/smb_story.png" width="150px"/>
									</div>
									<div className="newsimg newsimgs">
										<a href="https://yourstory.com/smbstory/noida-entrepreneur-ecommerce-save-water-online-shopping-fashion-clothing-jewellery-home-decor/amp?utm_pageloadtype=scroll" target="_blank"><img src="img/media_img/16/Capture.JPG" width="100%"/></a>
									</div>
								</div>
							</div>
							
							
							<div className="col-lg-3 col-md-6 col-sm-6">
								<div className="media_image">
									<div className="medialogo">
										<img src="img/media_img/logo/silicon_india.png" width="150px"/>
									</div>
									<div className="newsimg newsimgs">
										<a href="https://industry.siliconindia.com/viewpoint/cxoinsights/future-of-textile-technology-and-apparels-trend-nwid-18463.html" target="_blank"><img src="img/media_img/17/17.JPG" width="100%"/></a>
									</div>
								</div>
							</div>
							<div className="col-lg-3 col-md-6 col-sm-6">
								<div className="media_image">
									<div className="medialogo">
										<img src="img/media_img/logo/deccan_herald.png" width="150px"/>
									</div>
									<div className="newsimg newsimgs">
										<a href="https://www.deccanherald.com/metrolife/metrolife-lifestyle/brands-use-tech-to-save-water-759091.html" target="_blank"><img src="img/media_img/18/Capture.JPG" width="100%"/></a>
									</div>
								</div>
							</div>
							<div className="col-lg-3 col-md-6 col-sm-6">
								<div className="media_image">
									<div className="medialogo">
										<img src="img/media_img/logo/thehindu_business_line.png" width="150px"/>
									</div>
									<div className="newsimg newsimgs">
										<a href="https://www.thehindubusinessline.com/companies/this-noida-based-apparel-retailer-runs-on-a-non-warehouse-model/article29277804.ece" target="_blank"><img src="img/media_img/19/Capture.JPG" width="100%"/></a>
									</div>
								</div>
							</div>
							<div className="col-lg-3 col-md-6 col-sm-6">
								<div className="media_image">
									<div className="medialogo">
										<img src="img/media_img/logo/thenew_indianexpress_red.png" width="150px"/>
									</div>
									<div className="newsimg newsimgs">
										<a href="https://www.smeworld.asia/Interviews.aspx?Interviews=Interv-257/iba-striving-to-bring-indian-industry-on-global-e-commerce-platforms-#.YWAYWNJBzIV" target="_blank"><img src="img/media_img/20/Capture.JPG" width="100%"/></a>
									</div>
								</div>
							</div>
							<div className="col-lg-3 col-md-6 col-sm-6">
								<div className="media_image">
									<div className="medialogo">
										<img src="img/media_img/logo/thenew_indianexpress_red.png" width="150px"/>
									</div>
									<div className="newsimg newsimgs">
										<a href="https://www.newindianexpress.com/cities/kochi/2019/aug/08/fast-fashion-redefined-2015580.html" target="_blank"><img src="img/media_img/21/21.JPG" width="100%"/></a>
									</div>
								</div>
							</div>
							<div className="col-lg-3 col-md-6 col-sm-6">
								<div className="media_image">
									<div className="medialogo">
										<img src="img/media_img/logo/outlook_media.png" width="150px"/>
									</div>
									<div className="newsimg newsimgs">
										<a href="https://www.theindustryoutlook.com/manufacturing/industry-experts/future-of-textile-technology-and-apparels-trend-nwid-53.html" target="_blank"><img src="img/media_img/22/Capture.JPG" width="100%"/></a>
									</div>
								</div>
							</div>
							<div className="col-lg-3 col-md-6 col-sm-6">
								<div className="media_image">
									<div className="medialogo">
										<img src="img/media_img/logo/Business_standard.png" width="150px"/>
									</div>
									<div className="newsimg newsimgs">
										<a href="https://www.theindustryoutlook.com/manufacturing/industry-experts/future-of-textile-technology-and-apparels-trend-nwid-53.html" target="_blank" title=""><img src="img/media_img/23/Capture.JPG" width="100%"/></a>
									</div>
								</div>
							</div>
							<div className="col-lg-3 col-md-6 col-sm-6">
								<div className="media_image">
									<div className="medialogo">
										<img src="img/media_img/logo/rediffdotcom.png" width="150px"/>
									</div>
									<div className="newsimg newsimgs">
										<a href="https://www.rediff.com/business/special/is-the-age-of-smart-clothing-here/20190721.htm" target="_blank"><img src="img/media_img/23/Capture.JPG" width="100%"/></a>
									</div>
								</div>
							</div>
							<div className="col-lg-3 col-md-6 col-sm-6">
								<div className="media_image">
									<div className="medialogo">
										<img src="img/media_img/logo/ciol.png" width="150px"/>
									</div>
									<div className="newsimg newsimgs">
										<a href="https://www.ciol.com/future-of-textile-technology-apparels-trend-2050/" target="_blank"><img src="img/media_img/25/Capture.JPG" width="100%"/></a>
									</div>
								</div>
							</div>
							<div className="col-lg-3 col-md-6 col-sm-6 col-6 col-sm-6">
								<div className="media_image">
									<div className="medialogo">
										<img src="img/media_img/logo/franchiseindia.png" width="150px"/>
									</div>
									<div className="newsimg newsimgs">
										<a href="https://www.franchiseindia.com/content/digitalisation-has-changed-e-commerce-with-safer-online-transactions-superfast-checkouts-nitin-kapoor.13347" target="_blank"><img src="img/media_img/26/Capture.JPG" width="100%"/></a>
									</div>
								</div>
							</div>
							<div className="col-lg-3 col-md-6 col-sm-6">
								<div className="media_image">
									<div className="medialogo">
										<img src="img/media_img/logo/ENTREPENEUR.png" width="150px"/>
									</div>
									<div className="newsimg newsimgs">
										<a href="https://www.entrepreneur.com/article/332535" target="_blank"><img src="img/media_img/27/Capture.JPG" width="100%"/></a>
									</div>
								</div>
							</div>
							<div className="col-lg-3 col-md-6 col-sm-6">
								<div className="media_image">
									<div className="medialogo">
										<img src="img/media_img/logo/the-news-of-india.png" width="150px"/>
									</div>
									<div className="newsimg newsimgs">
										<a href="http://www.uniindia.com/home.aspx" target="_blank"><img src="img/media_img/28/Capture.JPG" width="100%"/></a>
									</div>
								</div>
							</div>
							<div className="col-lg-3 col-md-6 col-sm-6">
								<div className="media_image">
									<div className="medialogo">
										<img src="img/media_img/logo/financial express.png" width="150px"/>
									</div>
									<div className="newsimg newsimgs">
										<a href="https://www.financialexpress.com/industry/this-fashion-tech-firm-can-print-fabric-and-dispatch-it-in-just-48-hours/1529871/" target="_blank"><img src="img/media_img/29/Capture.JPG" width="100%"/></a>
									</div>
								</div>
							</div>
						</div>
					</div>
				 </section>
				 <section id="featured-services" className="featured_bottom">
					<div className="container">
						<div className="row">
							<div className="col-md-12">
								<h3 className="text-center">
									UNDERSTAND US BETTER
								</h3>
								<h3 className="text-center">
									BOOK AN APPOINTMENT WITH US
								</h3>
								
								<div className="cont_button text-center media-buttion-mar-t">
									<a href="/contact" title="Contact Us" target="_blank"><button className="btn btn-info">CONTACT US</button></a>
								</div>
							</div>
						</div>
					</div>
					
					
					<Modal open={this.state.onOpenModaluser} onClose={this.onCloseModal1user} center classNames={{modal: 'customModal'}} >
					       {this.state.onOpenModaluser}
						   
						  <div className="newsimg">
							<img src={"img/media_img/popup/"+this.state.currentImage} width="100%"/>
						 </div>
						   
						</Modal>
					
					
				</section>
				<Footer/>
			</div>);		
    }
}
 
 