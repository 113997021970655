import React, { Component } from "react";

export default class HomeBanner extends Component{

 render() {
	  return(
	<div className="HomeBanner">
	  <section id="hero" className="d-flex align-items-center scrolled-offset">
		<div className="container" data-aos="zoom-out" data-aos-delay="100">
		  <h1>Let’s Work Together To Do Amazing Things Together!</h1>
		  <h2 className="text-center">Your Success Is Our Success</h2>
		</div>
	  </section>
    </div>
	  );
	  }
	  }
	  
	  
	  


 