import React, { Component } from "react";
//import {Helmet} from "react-helmet";
import Header from './Header';
import Footer from './Footer';
import ContactBanner from '../common/ContactBanner';
//import { PopupWidget } from "react-calendly";
import { PopupButton } from "react-calendly";
import SimpleReactValidator from 'simple-react-validator';
const API_HOST="http://192.168.1.224/API_inkdotpot/ibaCraftsdata/";
export default class Contact extends Component{
	constructor(props){
         super(props);	
		
			this.state = {
				fname:'',
				lname:'',
				email:'',
				phone:'',
				Company:'',
				open:false,
				url:'',
				review:'',
				code:'',
				social_med:'',
				message:'',
				active:'contact'
			}
				  this.validator = new SimpleReactValidator(); 
	              this.setFormValue=this.setFormValue.bind(this);
	              this.getValidation=this.getValidation.bind(this);
	}
	
	setFormValue(event){
		this.setState({[event.target.name]:event.target.value},function(){})	
	}
	 getValidation(){
		if(!this.validator.allValid())
        {
            this.validator.showMessages();
            this.forceUpdate();
        }
		else{
			let qstr = '?typ=save_addr';
			
	        fetch(API_HOST+'ibaCraftsData.php'+qstr, {
                method: 'post',
                body: JSON.stringify({Fname:this.state.fname,
				                      Lname:this.state.lname,
									  Email:this.state.email,
									  phone:this.state.phone,
									  Compnay:this.state.Company,
									  Website:this.state.url,
									  description:this.state.review,
									  social_media:this.state.social_med
									  })
                
            }).then(response =>{
                if (response.ok){
                    response.json().then(json =>{
                       let response =  JSON.parse(JSON.stringify(json));
				this.setState({fname:'',lname:'',
								email:'',
								phone:'',
								Company:'',url:'',
								review:'',
								code:'',
								social_med:'',},function(){})				
                    });
                }  
			})
			
		}
	}
	
  
	
	showAddresses(){
			  return(<div className="row gty" style={{marginTop:'10px',backgroundColor: 'rgb(145 143 142 / 32%)', padding:"10px", margin:"0px"}}>
			<div className="row">
				<div className="col-lg-3 col-md-6 col-sm-6 col-6">
				<div className="text-center">
			<h5 className="columnB">USA</h5>
			<p className="columnB">
			<p className="columnA">IBA CRAFTS LLC</p>
			<p className="columnA">1910 Thomes Ave.</p>
			<p className="columnA">Cheyenne, Wyoming</p>
			<p className="columnA">82001</p>
			<p className="columnA">Tel: 06142 - 497 90 90</p>
			</p>
		  </div>
				</div>
				<div className="col-lg-3 col-md-6 col-sm-6 col-6 verticallineu ver-4">
					<div className = ""></div>
		  <div className="text-center">
			<h5 className="columnB">UK</h5>
			<p className="columnB">
			<p className="columnA">IBA CRAFTS PVT LTD</p>
			<p className="columnA">Unit 27, Slough Business Park</p>
			<p className="columnA">94 Farnham Road</p>
			<p className="columnA">Slough, SL1 3FQ</p>
			<p className="columnA">Tel: 06142 - 497 90 90</p>
			</p>
		  </div>
				</div>
				<div className="col-lg-3 col-md-6 col-sm-6 col-6 verticallineu">
					<div className = ""></div>
		  <div className="text-center">
			<h5 className="columnB">GERMANY</h5>
			<p className="columnB">
			<p className="columnA">IBA CRAFTS PVT LTD</p>
			<p className="columnA">Robert Koch Strasse 50, Geb. H</p>
			<p className="columnA">DEL 12983</p>
			<p className="columnA">Mainz 55129</p>
			<p className="columnA">Tel: 06142 - 497 90 90</p>
			</p>
		  </div>
				</div>
				<div className="col-lg-3 col-md-6 col-sm-6 col-6 verticallineu ver-4">
					<div className = ""></div>
		  <div className="text-center">
			<h5 className="columnB">AUSTRALIA</h5>
			<p className="columnB">
			<p className="columnA">IBA CRAFTS PVT LTD</p>
			<p className="columnA">AIC 56a Anzac Street</p>
			<p className="columnA">Mainz 55129</p>
			<p className="columnA">Chullora, NSW 2190</p>
			<p className="columnA">Tel: +612 9714 5866</p>
			</p>
		  </div>
				</div>
			</div>

		  
		  
		  {/* <div className = "verticalline1"></div>
		  <div className="column">
			<h5 className="columnB">GERMANY</h5>
			<p className="columnB">
			<p className="columnA">IBA CRAFTS PVT LTD</p>
			<p className="columnA">Robert Koch Strasse 50, Geb. H</p>
			<p className="columnA">DEL 12983</p>
			<p className="columnA">Mainz 55129</p>
			<p className="columnA">Tel: 06142 - 497 90 90</p>
			</p>
		  </div> */}
		  
		  
		</div>)
	}
	
	
 render() {
	  return(
		 <div className="Contact">
		 <Header act="contact"/>
		 <ContactBanner/>
		 
		 <section className="form-con-mob" style={{paddingBottom:'95px'}}>
    <div className="container">
        <div className="table_width">
            <div className="row">
                <div className="col-lg-6 col-md-12 form-lenth">
                    <div className="table_fom table-wirdth">
                        <div className="row">
                            <div className="col-md-3">
                                <p>First Name</p>
                            </div>
                            <div className="col-md-9">
                                <input type="text" name="fname" className="form-control" placeholder="example:John" 
                                    value={this.state.fname} onChange={this.setFormValue}/>
                                <span style={{color:'red'}}>{this.validator.message('fname', this.state.fname, 'required|alpha')}</span>
                            </div>
                            
                            <div className="col-md-3">
                                <p>Last Name</p>
                            </div>
                            <div className="col-md-9">
                                <input type="text" name="lname" className="form-control" placeholder="example:Deo" value={this.state.lname} onChange={this.setFormValue}/>
                                <span style={{color:'red'}}>{this.validator.message('lname', this.state.lname, 'required|alpha')} </span>
                            </div>
                            
                            <div className="col-md-3">
                                <p>Work Email</p>
                            </div>
                            <div className="col-md-9">
                                <input type="text" name="email" className="form-control" placeholder="eg:john.deo@example.com" 
                                value={this.state.email} onChange={this.setFormValue}/>
                                <span style={{color:'red'}}>{this.validator.message('email', this.state.email, 'required|email')}</span>
                            </div>
                            
                            <div className="col-md-3">
                                <p>Phone | Ext</p>
                            </div>
                            <div className="col-md-6">
                                <input type="text" name="phone" className="form-control" placeholder="eg:+1(888)888-8888-110" 
                                value={this.state.phone} onChange={this.setFormValue}/>
                                <span style={{color:'red'}}>{this.validator.message('phone', this.state.phone, 'required|phone|min:10|max:10')}</span>
                            </div>
                            
                            <div className="col-md-3">
                                <input type="text" className="form-control" placeholder="222" />
                            </div>
                            
                            <div className="col-md-3">
                                <p>Company</p>
                            </div>
                            <div className="col-md-9">
                                <input type="text" name="Company" className="form-control" placeholder="eg:example" 
                                value={this.state.Company} onChange={this.setFormValue}/>
                                <span style={{color:'red'}}>{this.validator.message('Company', this.state.Company, 'required|alpha')}</span>
                            </div>
                            <div className="col-md-3">
                                <p>Website URL</p>
                            </div>
                            <div className="col-md-9">
                                <input type="text" name="url" className="form-control" placeholder="eg:http//example.com" 
                                value={this.state.url} onChange={this.setFormValue}/>
                                <span style={{color:'red'}}>{this.validator.message('url', this.state.url, 'required|url')}</span>
                            </div>
                            <div className="col-md-3">
                                <p>Company size</p>
                            </div>
                            <div className="col-md-9">
                                <select name="employee" id="10">
                                  <option value="volvo">5</option>
                                  <option value="saab">10</option>
                                  <option value="mercedes">15</option>
                                  <option value="audi">20</option>
                                </select>
                            </div>
                            <div className="col-md-3">
                                <p>Tell us about your needs</p>
                            </div>
                            <div className="col-md-9">
                            
                                <textarea id="" name="review" rows="4" cols="50"
                                value={this.state.review} onChange={this.setFormValue} placeholder="Tell us about your needs">
                                    Tell us about your needs
                                </textarea>
                                <span style={{color:'red'}}>{this.validator.message('review', this.state.review, 'required')}</span>
                            </div>
                            <div className="col-md-3">
                                <p>Learned form</p>
                            </div>
                            <div className="col-md-9">
                                <input type="text" name="social_med" className="form-control" placeholder="Web search,social media,a friend" 
                                value={this.state.social_med} onChange={this.setFormValue}/>
                                <span style={{color:'red'}}>{this.validator.message('social_med', this.state.social_med, 'required|alpha')}</span>
                            </div>
                            
                            <div className="col-lg-8 col-md-6 col-sm-6 col-6 fom_botn mt-2 btn-left send-massage-ntn send-massages massage-box-s-ze">
                                <a className="btn btn-info" onClick={this.getValidation}>Send message</a>
                            </div>
                            <div className="col-lg-4 col-md-6 col-sm-6 col-6 fom_botn mt-2 btn-left massage-box-s-ze">
                                <div className="custm_btn">
                                    <PopupButton 
                                        url="https://calendly.com/ibacrafts/30min?hide_event_type_details=1&hide_gdpr_banner=1"
                                        text="Book Meeting"
                                        className="booking_button"
                                    />
                                </div>
                            </div>
                            <div className="col-md-12">
                                <p className="text-center">We keep any info you share with us private and confidential.For more on how we process and protect data,please review IBA Crafts's <a style={{color:'#5a81b5'}} href="/PrivacyPolicy"  target="_blank" title="Privacy Policy"><strong> Privacy Policy</strong></a></p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-1"></div>
                <div className="col-lg-5 col-md-12 send-massage-cen">
                    <div className="form_right">
                        <div className="row">
                            <div className="col-md-4 co-sm-6">
                                <img className="human" src="../img/cc_img.png" width="100%" />
                            </div>
                            <div className="col-md-8 mt-5">
                            <p className="mt-4 ">Send us a message<br/> to schedule a meeting</p>
                            
                            </div>
                            <div className="col-md-12">
                                <ul className="mt-4">
                                    <li>Secure your appointment</li>
                                    <li>Tell us about your business</li>
                                    <li>Find out if we're a good fit</li>
                                    <li>Get the specifics about our services</li>
                                </ul>
                                <div className="comn_content">
                                    <h3><a href="tel:+0120-4111437" title="Contact Us" target="_blank" ><img src="img/phone_icon.png" width="37px"/> +0120-4111437</a></h3>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-12 col-sm-12 text-center sizeofstatic">
                    <div className="fom_inner_content">
                        <p></p>
                    </div>
                </div>
            </div>
        </div>
    </div>
 </section>

 <section  style={{paddingBottom:'0px'}}>
    {this.showAddresses()}
 </section>
		
		<Footer/>
    </div>
	  )
	  }
	  }
	  
	  
	  


 