import React, { Component } from "react";
import Header from './Header';
import Footer from './Footer';
import HomeBanner from '../common/HomeBanner';

export default class Home extends Component{
constructor(props){
         super(props);	
		
			this.state = {
				active:'home'
			}
}
 render() {
	  return(
	<div className="Home">
	   <Header act={this.state.active}/>
	   <HomeBanner />
	  <main id="main">
		<section id="featured-services" className="featured-services border_before">
			<div className="container">
				<div className="row">
					<div className="col-md-12">
				<h3 className="text-center mb-4">
					What if we told you that you don’t need to hold inventory? Sounds impossible, right? Watch us make the unbelievable happen!
				</h3>
				<p style={{margin:' 0 38px',fontSize:'17px',textAlign:' center'}}>
					One of the biggest problems facing modern fashion brands today is managing inventory. Handling piles upon piles of clothing items requires ample space and that space costs money! Especially when you cater to a large customer base, inventory costs take a chunk out of your capital every single year and the financial statements are proof! As the world go virtual, you might feel sidetracked but why should you when you can hold virtual inventory instead? With IBA Craft’s innovative JIT model, fashion companies can jump on the bandwagon just like the rest!
				</p>
			  </div>
				</div>
			</div>
		</section>
		<section id="featured-services" className="featured-services"  style={{background: '#d3d3d359'}}>
		  <div className="container" data-aos="fade-up">
			<div className="row">
			  <div className="col-md-6 col-lg-4 d-flex align-items-stretch mb-5 mb-lg-0">
				<div className="icon-box" data-aos="fade-up" data-aos-delay="100">
				  
				  <h4 className="title"><a href="" title="Automation Techniques">The power of our process automation techniques</a></h4>
				  <p className="description">At IBA Craft, it all happens right from scratch yet it takes very little time and that’s exactly we help our partners formulate stellar strategies. Imagine receiving the garment you ordered within just two days of letting your supplier know! As unbelievable as it sounds, it’s 100% true. Our amazing process automation techniques ensure that the garment is ready as soon as two days. We don’t believe in making things in anticipation which is why our JIT approach has been so successful. Needless to say, our partners can vouch for the world of opportunities we have opened their doors to!</p>
				</div>
			  </div>

			  <div className="col-md-6 col-lg-4 d-flex align-items-stretch mb-5 mb-lg-0">
				<div className="icon-box" data-aos="fade-up" data-aos-delay="200">
				  
				  <h4 className="title"><a href="" title="Kill Two Birds With One Stone">Kill two birds with one stone</a></h4>
				  <p className="description">Are you tired of paying thousands in inventory holding costs only to bear the burden of unsold items later? Even the most resilient fashion brands today struggle to grapple with these problems. So, whether you’re a start-up eager to turn things around or an established company searching for viable ways that can cut down its costs, look no further. Working with IBA craft can be your one-way ticket to better production strategies, faster supply chain processes, and enhanced overall management. Plus, we guarantee that you won’t find the cost benefits we offer elsewhere!</p>
				</div>
			  </div>

			  <div className="col-md-12 col-lg-4 d-flex align-items-stretch mb-5 mb-lg-0">
				<div className="icon-box" data-aos="fade-up" data-aos-delay="300">
				  
				  <h4 className="title"><a href="" title="Commercially Viable">We are commercially viable</a></h4>
				  <p className="description">Partnering with a company that requires inventory investment is a huge leap of faith. You’re investing your hard earned money and relying on a severely reused concept that may or may not yield results. With IBA Craft, you don’t have to worry about any of that! Our business model is commercially viable and backed by the best technologies across the globe!</p>
				</div>
			  </div>
			</div>

		  </div>
		</section>
		
		<section className="video-power"
		// style={{padding:'0px',backgroundColor:"#e3dfdf", padding:"30px 0px 30px 0px"}}
		>
			<div className="container-fluid">			
				<div className="row">
					<div className="col-lg-6">
						<div className="video-box">
							<video width="100%" height="380" controls>
								<source src="../img/iba_video_final_video.mp4" type="video/mp4"/>
								<source src="../img/ibaiba_video_final_video.mp4" type="video/ogg"/>
								Your browser does not support the video tag.
							</video>
						</div>
					</div>
					
					<div className="col-lg-6">
						<div className="matter-text-box">
						<h3 className="iba-head">Why IBA Crafts?</h3>
						<div className="iba-box">
							<p className="iba-text">No matter the size of your order,</p>
							<p className="iba-text">Garments are made-on-demand within 24-48 hours</p>
							<a className="iba-btn" href="https://ibacrafts.com/SiteService" target="_blank" title="Details">Know More Details ....</a>
						</div>

						<div className="technology-text">
							<p className="iba-text our-techo-mar">
							Our technology at IBA Crafts enable garment manufacturing, resulting in the creation of on-demand, made-to-order, for brands and online merchants to get sewn goods to customers in the shortest time.
							</p>

							<div className="row">
							<div className="col-md-3 col-sm-3 col-3">
								<div className="icon-iba">
									<img className="icon-img-set" src ="../img/Home.png"/>
									<p className="dsign-text">Your Design</p>
								</div>
							</div>

							<div className="col-md-3 col-sm-3 col-3">
								<div className="icon-iba">
									<img className="icon-img-set" src ="../img/Home22.png"/>
									<p className="dsign-text">Our Technology</p>
								</div>
							</div>

							<div className="col-md-3 col-sm-3 col-3">
								<div className="icon-iba">
									<img className="icon-img-set" src ="../img/Home33.png"/>
									<p className="dsign-text">Just In Time Manufacturing</p>
								</div>
							</div>

							<div className="col-md-3 col-sm-3 col-3">
								<div className="icon-iba">
									<img className="icon-img-set" src ="../img/Home44.png"/>
									<p className="dsign-text">Logistic Solution</p>
								</div>
							</div>
							</div>
						</div>
						
							

							
						</div>

					</div>
					
				</div>
			</div>
		</section>
		
		<section id="services" className="services">
		  <div className="container aos-init aos-animate" data-aos="fade-up">

			<div className="section-title">
			  <h3>BENEFITS FOR ALL BRANDS, RETAILERS, AND ONLINE MERCHANTS</h3>
			  <h5 style={{color:"#fff"}}>Every order size can be fulfilled, big or small.</h5>
			</div>

			<div className="row">
			  <div className="col-lg-4 col-md-6 d-flex align-items-stretch aos-init aos-animate" data-aos="zoom-in" data-aos-delay="100">
				<div className="icon-box">
				  <img src="../img/brandbenifit/brands.jpg" width="100%" />
				  <h4><a href="">BRANDS</a></h4>
				  <p className="mb-3">Get Garment with an on-demand production process, all at the best reasonalble price.</p>
				  <p className="mb-3">As a brand, get your garment designed with our expert team and Display them in your store with as low as 1 sample piece, Place the order with IBA Crafts only once you have received the order.</p>
				  <p>Generate more revenue from existing assets without any investment in product development. Save money and avoid leftover stock—the products you sell are created only when your customer places an order.</p>
				</div>
			  </div>

			  <div className="col-lg-4 col-md-6 d-flex align-items-stretch mt-4 mt-md-0 aos-init aos-animate" data-aos="zoom-in" data-aos-delay="200">
				<div className="icon-box">
				  <img src="../img/brandbenifit/retailers.jpg" width="100%" />
				  <h4><a href="">RETAILERS</a></h4>
				  <p className="mb-3">Become sustainable with one or few garments at a time. As a retailer, you need Garments made in large volumes to get better selling price. </p>
				  <p className="mb-3">But with our technology you can get any garment of your interest in as low quantity as 1 piece. You can get it remade anytime from us depending on your demand.</p>
				  <p>Harness virtual stock and only produce what you actually sell resulting in no stock risk, no risk of stagnant inventory, and no loss of margin to clear unsold inventory.</p>
				</div>
			  </div>

			  <div className="col-lg-4 col-md-12 d-flex align-items-stretch mt-4 mt-lg-0 aos-init aos-animate" data-aos="zoom-in" data-aos-delay="300">
				<div className="icon-box">
				  <img src="../img/brandbenifit/onlinemerchant.jpg" width="100%" />
				  <h4><a href="">ONLINE MERCHANTS</a></h4>
				  <p className="mb-3">Test demand for new Garment designs. You have an online store and sell garment on ecommerce platforms. Display a huge virtual catalog to your customers with our AR/VR tech. Offer them multiple different prints and array of colors with our virtul catalog.</p>
				  <p className="mb-3">No need to keep the inventory plus you will never go out of stock with us. Create multiple  brands and sell across the Globe and we will handle your logistic.</p>
				  <p>When Garments are made on-demand, less leftover inventory winds up in landfills.</p>
				</div>
			  </div>
			</div>

		  </div>
		</section>
		
		<section id="featured-services" className="featured-servicess">
			<div className="container">
				<div className="row">
					<div className="col-md-12">
				<h3 className="text-center">
					"If you're not part of the problem, then you're part of the solution"?
				</h3>
				<p className="text-center">
					This may sound dramatic, but in this case, it is entirely true. If you continue to support the traditional garment industry, it will continue to grow and dominate the global garment market, costing us money, adding to our stress, and harming the only planet that we can call home. Textech is a viable alternative to the old way of doing things, and to learn more about it; you can visit us online at  <a target="_blank" title ="www.inkmeso.com" style={{color:'#5a81b5',}} href="http://inkmeso.com/">www.inkmeso.com</a>
				</p>
			  </div>
				</div>
			</div>
		</section>
		
		<section id="featured-services" className="featured_bottom">
			<div className="container">
				<div className="row">
					<div className="col-md-12">
						<h3 className="text-center mb-4">
							Future of Textile Technology and Apparels Trend
						</h3>
						<p>
							It is a human tendency to get attracted to what other person is wearing and wishing you had the same outfit. When we fantasize about the future, we are rigid when it is about the attitude towards clothing. Although the processes, styles, materials, and techniques of the past keep recycling in the name of ‘heritage’ or ‘fashion.’
						</p>
						<p>
							Textile Technology is in its infant phase where it is warming not only in the perspective of the ‘Game-Changing New Technology’ idea but its actual potential is tested on the advancement of what consumer wears, how apparels are designed, manufactured and marketed to consumers.
						</p>
						<p>
							At present, India is a center of manufacturing and production; our exceptional Textile technology (TEXTECH) powered Just In Time Manufacturing production is right here in India that makes us the best partner for all of your Garment sourcing needs, as India is one of the best manufacturing hubs for garment production.
						</p>
						<div className="cont_button text-center">
							<a href="/contact" title="Contact" target="_blank"><button className="btn btn-info">Contact Us</button></a>
						</div>
					</div>
				</div>
			</div>
		</section>
		</main>
		<Footer/>
    </div>
	  );
	  } }
	  
	  
	  


 