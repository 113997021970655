import React,{Component} from 'react';
 
export default  class Banner extends Component {
	
	constructor(props){
         super(props);
		 this.state = {
				
			};
	}
	
	render() {
		return (<div className="banner_block margbot15">
					<a className="banner nobord" href="javascript:void(0);" ><img src="/images/tovar/banner21.jpg" alt="" /></a>
				</div>) 
	}
}
