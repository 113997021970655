import React,{Component} from 'react';
import Banner from './Banner'; 
import Shop_block from './Shop_block'; 
import CatalogBanner from '../common/CatalogBanner';
/* import { css } from "@emotion/core";
const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
  z-index:9999;
  
`; */

export default class Category_content extends Component {
	constructor(props){
         super(props);
		 let aaa=this.props.p > 0 ? this.props.p : 1;
		 this.state = {
				//CategoryProductListAPI : 'https://s4sassy.com:455/getProductOfCategory/'+this.props.catId+"/"+this.props.prdctPerPage+"/"+aaa,
				//CategoryProductListAPI : 'https://banithani.com:455/getProductOfCategory/26/28/1',
				CategoryProductListAPI : 'https://s4sassy.com:455/getCatalogOfIbaCrafts/56/28/1',
				ProductsFilterAPI : 'https://s4sassy.com:455/getFilteredResultNew/'+this.props.catId+"/"+this.props.prdctPerPage+"/"+aaa,
				baseIpUrl1:'http://52.8.10.81:5000/',
				isFromFilterd:false,
				isFromSearch:false,
				priceRangeValue:[{id:100,val1:'0-20'},{id:101,val1:'20-30'},{id:102,val1:'30-40'}],
				totalPages:'',
				currentPage:this.props.p,
				loading: true,
				categoryTreeData:[],
				noDataMsg:''
			};
			
			 
		}
	
	callbackFunction = (filterType,filterBase) => {
		
		var self=this;
		this.setState({'loading':true});
		
		this.setState({'isFromFilterd':true},function(){
			
			this.filterProducts(filterType,filterBase);
		});
	}
	
	filterProducts = (filterType,filterBase)=> {
		var self=this;
		let newData=[];
		let newData1={};
		let iter=0;
		
		this.getFilterdProducts(filterType,filterBase);
	}
	
	componentDidMount(){
				
	}
	UNSAFE_componentWillMount(){
		this.getCategoryProductList();
		
	}
	
	
	
	getCategoryProductList(){
		//alert(this.state.CategoryProductListAPI);
		fetch(this.state.CategoryProductListAPI , { 
            //mode: 'no-cors',
            method: 'GET',
			},
            ).then(response => {

            if (response.ok) 
			{
				response.json().then(json => {
					var productlist = JSON.parse(JSON.stringify(json));
					this.setState({"productlist": productlist},function()
					{
						if(this.state.productlist.length > 0){
							this.setState({totalPages:Math.ceil(productlist[0].total_count/this.props.prdctPerPage)},function(){
								this.setState({'loading':false});
							});
						}
						else{
							this.setState({noDataMsg:'No Data Available'})
						}
					});
			   });
            }
			else{
				console.log(response);
			}
            }).catch((error) => {
			console.log(error);
		});
	}
	

	
	
	
	render() 
	{
		return(<div>
		<CatalogBanner/>
		<section className=" partner-section-media">
		<div className="container">
			<div className="row">
				<div className="col-md-12 col-lg-12">
					<div className="site_top_contents">
						<p>Partnering with us ensures that you get all the images from the product shoot and list them as you please. Rest assured, your specifications related to color, print or fabric material as well as at least 5 to 6 different poses are strictly catered to. But that’s not all! We wish to see you succeed and thus, we can go the extra mile which is why we also include a video of the garment to help your customers get the full picture and make a purchase.</p>
					</div>
				</div>
			</div>
		</div>
		</section>
		<section className="shop catalog-spacing">
				<div className="container-fluid custom_container">
					<div className="row">
						<div className="col-lg-12 col-md-12 col-sm-12 padbot20">
							<Shop_block 
									isFromFilterd={this.state.isFromFilterd} 
									isFromSearch={this.state.isFromSearch}
									totalPages={this.state.totalPages} 
									catId={this.props.catId}
									p={this.props.p}
									catName={this.props.catName}
									bredcrumb1={this.props.bredcrumb1}
									productlist={this.state.productlist} /> 
							</div>
						</div>
					</div>
			</section>
			<section id="featured-services" className="featured_bottom">
			<div className="container">
				<div className="row">
					<div className="col-md-12">
						<h3 className="text-center mb-4">
							STILL NOT SURE?
						</h3>
						<p>
							We hope with the above product visuals, you can now imagine the possibilities of the products that
							you can offer to your customers and that also without keeping them in your inventory.
						</p>
						<p>
							We also know that you still have questions and doubts about quality of the product. That is why we have prepared a Sample kit for you that might solve all the remaining doubts. 
							Request a sample kit now.
						</p>
						<div className="cont_button text-center">
							<a href="/sampleKit" target="_blank" title="Sample Kit" className="btn btn-info">SAMPLE KIT</a>
						</div>
					</div>
				</div>
			</div>
		</section>
		
			</div>);		
	}
	
}
