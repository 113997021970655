import React, { Component } from "react";

export default class Catalog extends Component{

 render() {
	  return(
	<div className="Catalog">
	  <section id="Cataloging" className="d-flex align-items-center scrolled-offset heros">
		<div className="container mt-5" data-aos="zoom-out" data-aos-delay="100">
		  <h1 className="mt-6">CATALOGING</h1>
		</div>
	  </section>
    </div>
	  );
	  }
	  }
	  
	  
	  


 