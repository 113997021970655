import React,{Component} from 'react';
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';
 
export default class Product_info extends Component {
	
	constructor(props){
         super(props);
		 this.state = {
				description:'',
				bPoints:'',
			};
	}
	
	componentDidMount(){
		window.set_tab_action();
	}
	componentWillMount(){
		var allData=this.props.productInformation.custom_attributes.description.split("<!-- split -->");
		this.setState({description:allData[0]});
		this.setState({bPoints:allData[1]});
	}
	
   render() {
    return (<div class="tovar_information">
				<ul class="tabs clearfix">
					<li class="current">Details</li>
					<li>Information</li>
					<li>Reviews (2)</li>
				</ul>
				<div class="box visible">
					{ReactHtmlParser(this.state.description)}
				</div>
				<div class="box">
					{ReactHtmlParser(this.state.bPoints)}
				</div>
				<div class="box">
					<ul class="comments">
						<li>
							<div class="clearfix">
								<p class="pull-left"><strong><a href="javascript:void(0);" >John Doe</a></strong></p>
								<span class="date">2013-10-09 09:23</span>
								<div class="pull-right rating-box clearfix">
									<i class="fa fa-star"></i>
									<i class="fa fa-star"></i>
									<i class="fa fa-star"></i>
									<i class="fa fa-star-o"></i>
									<i class="fa fa-star-o"></i>
								</div>
							</div>
							<p>Ut tellus dolor, dapibus eget, elementum vel, cursus eleifend, elit. Aenean auctor wisi et urna. Aliquam erat volutpat. Duis ac turpis. Integer rutrum ante eu lacus.Vestibulum libero nisl, porta vel, scelerisque eget, malesuada at, neque.</p>
						</li>
						<li>
							<div class="clearfix">
								<p class="pull-left"><strong><a href="javascript:void(0);" >John Doe</a></strong></p>
								<span class="date">2013-10-09 09:23</span>
								<div class="pull-right rating-box clearfix">
									<i class="fa fa-star"></i>
									<i class="fa fa-star"></i>
									<i class="fa fa-star"></i>
									<i class="fa fa-star"></i>
									<i class="fa fa-star"></i>
								</div>
							</div>
							<p>Ut tellus dolor, dapibus eget, elementum vel, cursus eleifend, elit. Aenean auctor wisi et urna. Aliquam erat volutpat. Duis ac turpis. Integer rutrum ante eu lacus.Vestibulum libero nisl, porta vel, scelerisque eget, malesuada at, neque.</p>
							
							<ul>
								<li>
									<p><strong><a href="javascript:void(0);" >Jane Doe</a></strong></p>
									<p>Ut tellus dolor, dapibus eget, elementum vel, cursus eleifend, elit. Aenean auctor wisi et urna. Aliquam erat volutpat. Duis ac turpis. Integer rutrum ante eu lacus.Vestibulum libero nisl, porta vel, scelerisque eget, malesuada at, neque.</p>
								</li>
							</ul>
						</li>
					</ul>
					
					<h3>WRITE A REVIEW</h3>
					<p>Now please write a (short) review....(min. 200, max. 2000 characters)</p>
					<div class="clearfix">
						<textarea id="review-textarea"></textarea>
						<label class="pull-left rating-box-label">Your Rate:</label>
						<div class="pull-left rating-box clearfix">
							<i class="fa fa-star-o"></i>
							<i class="fa fa-star-o"></i>
							<i class="fa fa-star-o"></i>
							<i class="fa fa-star-o"></i>
							<i class="fa fa-star-o"></i>
						</div>
						<input type="submit" class="dark-blue big" value="Submit a review"/>
					</div>
				</div>
			</div>) 	
  }
}