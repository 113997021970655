import React,{Component} from 'react';
import Product_options from './Product_options';
import Product_info from './Product_info';
//import { css } from "@emotion/core";
/* import { GridLoader } from "react-spinners";

const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
  z-index:9999;
  
`; */

// const baseIpUrl = 'http://52.8.10.81:5000/';
//const baseIpUrl = 'http://207.180.202.213:5002/';
const baseIpUrl = 'https://s4sassy.com:455/';
//const imageUrl = 'https://s4sassy-ibacraftspvtltd.netdna-ssl.com/ibaweb/pub/media/catalog/product/';
const imageUrl = 'https://bubsbliss.com/ibaweb/pub/media/catalog/product/';
const thumbImageUrl = 'https://bubsbliss.com/thumb/';

export default class Quick_view extends Component {
	
	constructor(props){
        super(props);
		this.state = {
				loading: true,
			};		
			
	}
	componentWillMount(){
		this.getProductDetails(this.props.sku,function(error,productData){
			
		});
	}
	
	getProductDetails(sku,cb){
		console.log(baseIpUrl+'getAllVariationOfProduct/'+this.props.cid+"/"+this.props.pid );
		fetch(baseIpUrl+'getAllVariationOfProIbaCraft/'+this.props.cid+"/"+this.props.pid , {
			method: 'GET',
			},
            ).then(response => {			
				if (response.ok) {
					response.json().then(json => {
					var productData = JSON.parse(JSON.stringify(json));
						this.setState({"productData": productData},function(){	
						console.log(productData);
							this.setState({'loading':false});
							cb(null,'done');
						});
			});
				}
            }).catch((error) => {
			console.log(error);
			cb(error,null);
		});		
	}
	
	getProductDetails_OLD(sku,cb){  
	console.log(baseIpUrl+'getFullDetailsOfProduct/'+sku );
		fetch(baseIpUrl+'getFullDetailsOfProduct/'+sku , {
            method: 'GET',
			},
            ).then(response => {			
				if (response.ok) {
					response.json().then(json => {
					var productData = JSON.parse(JSON.stringify(json));
					
						this.setState({"productData": productData},function(){	
							this.setState({'loading':false});
							cb(null,'done');
						});
						
			});
				}
            }).catch((error) => {
			console.log(error);
			cb(error,null);
		});		
	}

	
   render() {
    return (<div class="col-lg-12 col-md-12 tovar_details_wrapper clearfix" >{/*<!-- TOVAR DETAILS WRAPPER -->*/}
				<div class="tovar_details_header clearfix margbot35">
				{/* <h3 class="pull-left"><b>Quick view</b></h3>  */}
				</div>
				
				{/*<!-- CLEARFIX -->*/}	
					{!this.state.loading ? 
						this.state.productData && <Product_options 
						productData={this.state.productData} 
						imageUrl={imageUrl} 
						thumbImageUrl={thumbImageUrl}
						cid={this.props.cid} 
						productInformation={Object.keys(this.state.productData['items'])[0]}
						category = {this.props.category}
						
						/> 
						:
						<div className="sweet-loading" style={{'margin-top':'100px'}}>
								
							</div>
					}
				 
				{/*<!-- //CLEARFIX -->*/}
				
			</div>) 	
	}
}