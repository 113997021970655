import React, { Component } from "react";

export default class SiteServiceBanner extends Component{

 render() {
	  return(
	<div className="SiteServiceBanner">
	  <section id="servicePage" className="d-flex align-items-center scrolled-offset heros">
		<div className="container" data-aos="zoom-out" data-aos-delay="100">
		  <h1>Why Choose Us?</h1>
		</div>
	  </section>
    </div>
	  );
	  }
	  }
	  
	  
	  


 