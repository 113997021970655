import React, { Component } from 'react';
import Header from './Header';
import Footer from './Footer';
import MediaBanner from '../common/MediaBanner';
import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal';


export default class Media extends Component{
	
	constructor(props)
	{
         super(props);
		 
		 
		 this.state={
			onOpenModaluser:false,
			currentImage:'',
        }
	}
	


    onOpenModaluser = (currentImage) => { 
		this.setState({ onOpenModaluser: true,currentImage: currentImage}); 
	}
  
	onCloseModal1user = (event) => {
         this.setState({ onOpenModaluser: false});
	};

	render(){
        return(<div>
				<Header />
				
				 <section className="mt-6 mb-4 border_befores">
					<div className="container">
						<div className="row">
							<div className="col-md-12">
								
								<div className="partner_login">
								<div className="user_icon  text-center">
									<i className="fa fa-user"></i>
								</div>
								<h4 className="text-center mb-3 mt-3" style={{color:'#6b6d7d',fontFamily:'system-ui'}}>MEMBER LOGIN</h4>
									<div className="partner_form">
										<form>
											<div className="input-group mb-3">
												<div className="input-group-append">
													<span className="input-group-text"><i className="fa fa-user"></i></span>
												</div>
												<input type="text" name="" className="form-control input_user" value="" placeholder="Username"/>
											</div>
											<div className="input-group mb-4">
												<div className="input-group-append">
													<span className="input-group-text"><i className="fa fa-key"></i></span>
												</div>
												<input type="password" name="" className="form-control input_pass" value="" placeholder="Password"/>
											</div>
											<div className="form-group">
												<div className="custom-control custom-checkbox" style={{display:'flex'}}>
													<input type="checkbox" className="custom-control-input" id="customControlInline" style={{display:'none'}}/>
													<label className="custom-control-label" for="customControlInline" style={{textTransform:'none'}}></label>
													
													<span>Remember Me</span>
												
													<span style={{marginLeft:'200px',}}><a href="#" title="Password">Forgot Password?</a></span>
												</div>
											</div>

											<div className="Login_button center mt-3">
												<button className="btn btn-info  btn-infos" title="Log In" style={{padding:'15px 80px',background:'#00aac3'}}>Login</button>
											</div>
										</form>
									</div>
								</div>
							</div>
						</div>
					</div>
				 </section>
				 
					
				<Footer/>
			</div>);		
    }
}
 
 