import React, { Component } from "react";

export default class AboutusBanner extends Component{

 render() {
	  return(
	<div className="AboutusBanner">
	  <section id="AboutusBanner" className="d-flex align-items-center scrolled-offset heros">
		<div className="container" data-aos="zoom-out" data-aos-delay="100">
		
		{/*  <h1>About Us</h1>
		<h3>Our Sample Kit Will Clear All Your Doubts</h3> */}
		</div>
	  </section>
    </div>
	  );
	  }
	  }
	  
	  
	  


 