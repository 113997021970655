import React, { Component } from "react";
import Header from './Header';
import Footer from './Footer';
import SamlpeKitBanner from '../common/SampleKitBanner';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export default class SampleKit extends Component{

 render() {
	 
	  var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
	slidesToShow: window.innerWidth < 640 ? 1 : 1,
	adaptiveHeight:false,
	arrows:false,
	autoplay:true,
	autoplaySpeed:2000
  };
	  return(
	  
	<div className="SampleKit">
	 <Header/>
	 <SamlpeKitBanner/>
	  
	  <section className="kit-pad-mob">
		<div className="container">
			<div className="row">
				<div className="col-md-12 col-lg-12">
					<div className="site_top_contentss">
						<p className="text-center">We understand that without a physical touch, it is difficult to believe in what we are saying. But do not worry because we have a soultion for this as well.Request our SAMPLE KIT to understand us and our offerings a little better. Possibilities are endless if you work with us. 
						</p>
						
					</div>
				</div>
			</div>
			
		</div>
		</section>
		
		<section className="mt-5 sam-img-mob">
			<div className="container">
				<div className="row mb-5">
					{/* <hr style={{width:'95%',margin:'16px auto 50px'}} className="mb-5"/> */}
						<div className="col-md-6">
						  <Slider {...settings}>

							  <div className="imghits">
								<img src="/img/samplekit/S1.jpg" width="100%"/>
							  </div>
							  <div className="imghits">
								<img src="/img/samplekit/S2.jpg" width="100%"/>
							  </div>
							  <div className="imghits">
								<img src="/img/samplekit/S3.jpg" width="100%"/>
							  </div>
							  <div className="imghits">
								<img src="/img/samplekit/S4.jpg" width="100%"/>
							  </div>
							  
							  <div className="imghits">
								<img src="/img/samplekit/S5.jpg" width="100%"/>
							  </div>
							  
							  <div className="imghits">
								<img src="/img/samplekit/S6.jpg" width="100%"/>
							  </div>
							  
							  <div className="imghits">
								<img src="/img/samplekit/S7.jpg" width="100%"/>
							  </div>
							  
							  <div className="imghits">
								<img src="/img/samplekit/S8.jpg" width="100%"/>
							  </div>
							  
							  <div className="imghits">
								<img src="/img/samplekit/S9.jpg" width="100%"/>
							  </div> 
							  
							  <div className="imghits">
								<img src="/img/samplekit/S10.jpg" width="100%"/>
							  </div>
						</Slider>
						</div>
						<div className="col-md-6">
							<h4 className="mb-5">What all is Included in Our Sample Kit?</h4>
							<h5 style={{marginTop:"-20px"}}>SPECIFICATIONS</h5>
							<h6>Our Kit Will Include:</h6>
							<ul className="sampleui mb-5">
								<li><b>2 Garments Samples</b>, to check on our stitching quality.</li>
								<li><b>Fabric Swatches,</b> to show you to variety of fabrics available with us.</li>
								<li><b>Technique Swatches,</b>like embroidery, sequins, graphic arts and other techniques to give you a feeler of things you can create with us.</li>
								<li> <b>Tags Sampler Swatch</b></li>
								<li><b>Accessory Pouch, </b>with few sample accessories.</li>
							</ul>
							
							<h4 className=""  style={{marginTop:"-20px"}}>How much does this sample kit costs?</h4>
							<h6 className="text_equal mb-5">Its completely free, just click on the below tab and fill in the required details.</h6>
							<h6 className="text_equal" style={{marginTop:"-30px"}}>A sample kit box will be sent to you and one of our consultant wil get in touch with you to guide you further.</h6>
							<div className="cont_button mt-5">
							<a href="/contact" target="_blank" title="Contact"><button className="btn btn-info" style={{marginTop:"-19px"}}>Send Me Sample Kit</button></a>
						</div>
						</div>
					
				</div><hr/>
			</div>
		</section>
		<section id="featured-services" className="featured_bottom">
			<div className="container">
				<div className="row">
					<div className="col-md-12">
						<p className="text-center">UNDERSTAND US BETTER</p>
						<p className="text-center">BOOK AN APPOINTMENT WITH US</p>
						<div className="cont_button text-center">
							<a href='/contact'target="_blank" title="Contact"><button className="btn btn-info">CONTACT US</button></a>
						</div>
					</div>
				</div>
			</div>
		</section>
	<Footer/>
		
    </div>
	  );
	  } }
	  
	  
	  


 