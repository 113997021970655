import React,{Component} from 'react';
import Slider from './Slider';
import Slider_2 from './Slider_2';
import sizecode from '../Json/sizecode';
import image_chart from '../Json/image_chart';
import shortedSizeArr from '../Json/sortedSizecode';
//import Question from '../product_view/question';
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';
import Modal from 'react-responsive-modal'; 
import 'react-responsive-modal/styles.css';
//import { GoogleLogin } from 'react-google-login';
//import SimpleReactValidator from 'simple-react-validator';
//mport ReactStars from "react-rating-stars-component";
const basePUrl = window.location.protocol + '//' +  window.location.host +"/";
const imageUrl = 'https://bubsbliss.com/inkmesoimages/';
const API_HOST = 'https://s4sassy.com/API_inkmeso/';
const API_HOST1='http://127.0.0.1/API_inkdotpot/';

export default class Product_options extends Component {
	
	constructor(props){
         super(props);	
		
		 this.state = {
				id : '',
				productTitle : '',
				sku : '',
				price : '',
				color : '',
				colorToShow : '',
				size : '',
				brand : 'Inkmeso',
				description : '',
				name:'',
				product_id:'',
				product_total_price:0,
				defaultProChildKey : '',
				productData: [],
				ReviewProducts:[],
				colorOptions: [],
				sizeOptions: [],
				productImages: [],
				swatchImages:[],
				addToCartButtonDisable: '',
				addToCartButtonStyle: {},
				outOfStock:'',
				bPoints:'',
				sizeChartImage:'',
				optQntity:1,
				bpnt1:'',
				bpnt2:'',
				bpnt3:'',
				bpnt4:'',
				bpnt5:'',
				bpnt6:'',
				bpnt7:'',
				bpnt8:'',
				sizeCodes:'',
				isVideoClicked:false,
				parent_sku:'',
				category_id:'',
				parent_id:'',
				activecolor:'#ffd700',
				Rating:1,
				email:'',
				review:'',
				email1:'',
				password:'',
				first_name:'',
				Parent_Id:'',
				cid:'',
				reviewDisabled:false,
				lname:'',
				message: '',
			
			};
			
			//this.validator = new SimpleReactValidator();
			this.setDefaultProductData = this.setDefaultProductData.bind(this);
			this.setAllvariationOptions = this.setAllvariationOptions.bind(this);
			this.handleSizeChange = this.handleSizeChange.bind(this);	
			this.videoClicked = this.videoClicked.bind(this);	
			this.setReviews = this.setReviews.bind(this);	
			this.add_review = this.add_review.bind(this);
			this.setFormValue = this.setFormValue.bind(this);
			this.doLogin = this.doLogin.bind(this);
			
	}
	
	componentWillMount () {  
		this.setState({'productData' :this.props.productData['items']},function(){
			this.setState({'swatchImages':this.props.productData['swatchImagesData']},function(){
				this.setState({'defaultProChildKey':Object.keys(this.state.productData)[0]},function(){
					this.setSizecode(sizecode);	
					this.setAllvariationOptions();
					this.setDefaultProductData();					
				});			
			});	
		});
		
		/* let idpsess = localStorage.getItem('idpsess');
		console.log(idpsess);
		if(typeof idpsess === undefined || typeof idpsess === 'undefined' ||  idpsess === null || idpsess === 'null'){
        }
		else
		{
            let userDta=JSON.parse(idpsess);
			this.setState({email1:userDta[0].email,first_name:userDta[0].first_name,lname:userDta[0].last_name})
			
		}  */
	}
	
	componentDidMount(){
		//window.set_tab_action();
		this.getReviewData();
	}
	
	setSizecode(sizecode){		
		var sizeCodes = [];
		sizecode.map((val,key)=>(
			sizeCodes[val.value]=val.label
		));	
		this.setState({'sizeCodes':sizeCodes});
	}
	
	setLastImage(){
		const doubleValue = this.state.productImages.map((key)=>{  
			if(Object.values(image_chart).includes(key))
				this.setState({sizeChartImage:"https://bubsbliss.com/imagebank/images/"+key},function(){
				});
		}); 
	}

	
	setDefaultProductData = (doUpdateImages=true) => { 
		try{
			var data = this.state.productData[this.state.defaultProChildKey];
			this.setState({'id':data.entity_id});
			this.setState({'productTitle':data.name});
			this.setState({'sku':data.sku});
			this.setState({'price':data.price});
			this.setState({'color':data.color_value});
			this.setState({'colorToShow':data.colormap});
			this.setState({'size':data.size_value});
			this.setState({'description':data.description});
			this.setState({'product_currency' : 'USD'});
			this.setState({'bpnt1':data.bullet_point1});
			this.setState({'bpnt2':data.bullet_point2});
			this.setState({'bpnt3':data.bullet_point3});
			this.setState({'bpnt4':data.bullet_point4});
			this.setState({'bpnt5':data.bullet_point5});
			this.setState({'bpnt6':data.bullet_point6});
			this.setState({'bpnt7':data.bullet_point7});
			this.setState({'bpnt8':data.bullet_point8});
			this.setState({'parent_sku':data.parent_sku});
			this.setState({'category_id':data.category_id});
			this.setState({'registered_buyer_id':100});
			this.setState({'parent_id':data.parent_id});
			
			if(doUpdateImages){
				this.setState({'productImages':data.other_image_url.split(",")}, function(){
					this.setLastImage();
				});
			}
			this.setState({'addToCartButtonDisable':''});
			this.setState({'addToCartButtonStyle':{}});
			this.setState({'outOfStock':''});
			
		}
		catch(e){
			//alert(e);
			this.setState({'addToCartButtonDisable':'disabled'});
			this.setState({'addToCartButtonStyle':{opacity:'0.5',cursor:'not-allowed'}});
			this.setState({'outOfStock':'Out of Stock'});
		}
	}
	
	setProductData(doUpdateImages){ 
		// alert("in Dtaa");
		this.setState({isVideoClicked:false,'defaultProChildKey':this.state.color+"-"+this.state.size},function(){
			this.setDefaultProductData(doUpdateImages);	
				
		});
	}
	
	setAllvariationOptions (){
		var productData = this.state.productData;
		var allKyes = Object.keys(productData);
		let keyVal=[];
		let colorVal=[];
		let sizeVal=[];
		
		var self=this;
		
		for(var i=0;i<allKyes.length;i++){
			 keyVal = allKyes[i].split('-');
			 colorVal.push(keyVal.shift());
			 sizeVal.push(keyVal.join("-"));
		}
		
		const distinct = (value,index, self) => {
			return self.indexOf(value) === index;
		}
		
		this.setState({'colorOptions':colorVal.filter(distinct)},function(){
			this.setState({'sizeOptions':sizeVal.filter(distinct)});
		});
	}
	
	showColorOptions = () => {
		var self = this;
		var swatchImagesObj = this.state.swatchImages;	
		return 	Object.keys(swatchImagesObj).map(function(key, index) {
					
					return <a className="variation-option" href="javascript:void(0);" 
							onClick={()=> self.setState({'color': key},function(){
								self.setProductData(true)
								}
							)} 
							style={{backgroundImage:'url('+self.props.thumbImageUrl+swatchImagesObj[key].replace('.jpg','_thumb.jpg')+')'}}
							>
							 
						</a>
				});
	}
	
	
	showSizeOptions = () => {
		var sizeCodes = this.state.sizeCodes;
		let size11=[];
		let sizeKeyVal = [];
		let intersection = shortedSizeArr.filter(x => this.state.sizeOptions.includes(x)); //Sort size in accending order
		return intersection.map((val,i)=>( 
				<a className={i==0 ? 'size1 active' : 'size1'} href="javascript:void(0);" 
				onClick={()=> this.setState({'size': val},function(){this.setProductData(false)})}>{val}</a>
		));
	}
	
	
	handleSizeChange(event) {
		this.setState({'size': event.target.value},function(){
			this.setProductData(false)
		});
	  }
	
	onOpenModal= (index)=>{	
		this.setState({ open: true });	
	};
 
	onCloseModal = () => {
		this.setState({ open: false });
	};
	
	setInLocalStorage=(type)=>{
		let myWishlist=[];
		 
		if(localStorage.getItem(type)!=null)
			myWishlist=JSON.parse(localStorage.getItem(type));
		
		myWishlist.push(
		{
			productTitle:this.state.productTitle,
			product_name:this.state.productTitle,
			sku:this.state.sku,
			price:this.state.price,
			product_total_price:this.state.price*parseInt(this.state.optQntity),
			catId:this.props.productData['items'][this.props.productInformation].category_id.split(",")[1],
			optQntity:this.state.optQntity,
			image:this.state.productImages[0].split("/").pop(),
			parent_sku:this.state.parent_sku,
			parent_id:this.state.parent_id,
			product_id:this.state.id,
			category_id:this.state.category_id,
			colormap:this.state.color,
			size_value:this.state.size
		
		});
		
		localStorage.setItem(type, JSON.stringify(myWishlist));
		window.location.reload(); 
	}
	
	change = (event)=>{
		this.setState({optQntity: event.target.value});
    }
	 
	videoClicked(isClick){
		this.setState({isVideoClicked:isClick},function(){
		});
	}
	
	onSuccess= (res) => {
		this.setState({email:res.profileObj.email},function(){
			this.emailVerifiedCommon('yes',res);
		})
	}
	onFailure= (res) => {
		console.log("can't do login");
	}
	setFormValue(event) {
       this.setState({[event.target.name]:event.target.value});
    }
	
	emailVerifiedCommon(isGoogleLogin,res='')
	{
			let queryStr = '?t=checkEmail';
			fetch(API_HOST+'myAccountNew.php'+queryStr, {
                method: 'post',
                body: JSON.stringify({'email':this.state.email,
				'isGoogleLogin':isGoogleLogin}),
                headers: {
                'Content-Type': 'application/json; charset=UTF-8',
                'Access-Control-Allow-Origin':'*',
                'Access-Control-Allow-Headers':'*'
                }
            }).then(response =>{
                if (response.ok){
                    response.json().then(json =>{
                       let response =  JSON.parse(JSON.stringify(json));
					   
						 if(response.length > 0 ){
							
							if(response === 'true')
								response=true;
							else
								response=false;
							
							if(isGoogleLogin === 'yes' && response)
							{
								let idpsess = [{id: 1, 'first_name':res.profileObj.name,'last_name':res.profileObj.familyName,'email':res.profileObj.email,'password':'Google Login','isGoogleLogin':'yes'}];
								localStorage.setItem('idpsess', JSON.stringify(idpsess));
								this.onCloseModal1user();
							}
							else if(isGoogleLogin === 'yes' && !response){
								this.registerCommon(res,isGoogleLogin);
								
							}
						}                      
                    });
                }
			})
    }
	
	registerCommon(res,isGoogleLogin){
		fetch(API_HOST+'UserRegister.php', {
						method: 'post',
						body: JSON.stringify({'first_name':res.profileObj.name,'last_name':res.profileObj.familyName, 'email':res.profileObj.email, 'password':'Google Login','isGoogleLogin':'yes'}),
						headers: {
						'Content-Type': 'application/json; charset=UTF-8',
						'Access-Control-Allow-Origin':'*',
						'Access-Control-Allow-Headers':'*'
						}
						}).then(response =>{
							if (response.ok){
								response.json().then(json =>{
									var data = JSON.parse(JSON.stringify(json));
									 if(data == 'duplicate'){
										 this.setState({email:res.profileObj.email,'password':'Google Login'},function(){
											 let idpsess = [{id: 1, 'first_name':res.profileObj.name,'last_name':res.profileObj.familyName,'email':res.profileObj.email,'password':'Google Login','isGoogleLogin':'yes'}];
											 localStorage.setItem('idpsess', JSON.stringify(idpsess));
											 this.onCloseModal1user();
										 })
									}else{ 
										let idpsess = [{id: data, 'first_name':res.profileObj.name,'last_name':res.profileObj.familyName,'email':res.profileObj.email,'password':'Google Login','isGoogleLogin':'yes'}];
										localStorage.setItem('idpsess', JSON.stringify(idpsess));
										this.onCloseModal1user();
									}                                    
								});                  
							}
							
						});
	}
	
	doLogin(event){
        
            let queryStr = '?t=login';
            fetch(API_HOST+'myAccount.php'+queryStr, {
                method: 'post',
                body: JSON.stringify({'email':this.state.email,'password':this.state.password}),
                headers: {
                'Content-Type': 'application/json; charset=UTF-8',
                'Access-Control-Allow-Origin':'*',
                'Access-Control-Allow-Headers':'*'
                }
            }).then(response =>{
                if (response.ok){
                    response.json().then(json =>{
                        var data = JSON.parse(JSON.stringify(json));
						if(data.length > 0){ 
							localStorage.setItem('idpsess',JSON.stringify(json)); //save data to local storage	
							window.location.reload();
                        }else{
                            document.querySelector("#invalid-error").style = {display:' block',color:'red'} 
                        }
                    });
                }
            });
        
        event.preventDefault();
    }
	
	ratingChanged = (newRating) => {
		this.setState({Rating:newRating});
	};	

	reviewSection()
	{
		return (<div class="box">
							<div>
							{this.state.ReviewProducts.map((result) => {
					     return (
						<ul class="comments">
								<li>
									<div class="clearfix">
										<p class="pull-left"><strong><a href="javascript:void(0);" >{result.name}</a></strong></p>
										<span class="date">{result.Date}</span>
										<div class="pull-right rating-box clearfix">
										
										</div>
									</div>
									<p>{result.review_text}</p>
								</li>
							</ul>	
						)})}
							</div>
							
							<h3>WRITE A REVIEW <span style={{fontSize:'12px',textTransform:'lowercase'}}>(review product upto 500 charecters</span>)</h3>
							
							<div class="clearfix">
							
							<textarea id="review-textarea" 
								name="review" 
								value={this.state.review} 
								onChange={this.setReviews} 
								disabled={this.state.reviewDisabled}>
							</textarea>
							
							<label class="pull-left rating-box-label">Your Rate:</label>
							<div class="pull-left rating-box clearfix">
							
							
							</div>
								<input type="submit" className="dark-blue big" value="Submit a review"  disabled={this.state.reviewDisabled}
								onClick={()=>this.checkLogin()} />
							</div>
							
							<Modal 
							open={this.state.onOpenModaluser} 
							onClose={this.onCloseModal1user} 
							center classNames={{modal: 'customModal'}} >
					       {this.state.onOpenModaluser && this.showLoginForm()}
						</Modal>
			</div>)
	}
	
	checkLogin()
	{
		let idpsess = localStorage.getItem('idpsess');
		if(typeof idpsess === undefined || typeof idpsess === 'undefined' ||  idpsess === null || idpsess === 'null'){
			this.onOpenModaluser();
		}
		else
		{
			let userDta=JSON.parse(idpsess);
			this.setState({email1:userDta[0].email,first_name:userDta[0].first_name,lname:userDta[0].last_name},function(){
				this.add_review();
			})
		} 
	}
	
	setReviews(event){
		this.setState({review:event.target.value});
	}
	
	onOpenModaluser = (event) => { 
	     this.setState({ onOpenModaluser: true}); 
	}
  
	onCloseModal1user = (event) => {
         this.setState({ onOpenModaluser: false});
	};
	
	
	showLoginForm(){
		return (
		  <div className="row order-details-page">  
            <section className="typography_block padbot40" style={{marginTop:'0px',fontSize:'14px',textAlign:'justify',padding:'12px'}}>	
				<div className="container">
					<div className="mainlog mb-5">
						<div className="row mt-3">
							<div className="col-md-6">
								<div className="Login mb-4">
									<h4 className="mb-4 mt-2" style={{fontWeight:'600'}}>Login </h4>
									<input type="text" name="email" value={this.state.email} id="email" className="form-control" placeholder="Email" style={{border:'none',borderBottom:'2px solid #ccc',padding:'18px'}} onChange={this.setFormValue}/>
									
									<br/>
									<input type="Password" name="password" id="password" className="form-control" placeholder="Password" style={{border:'none',borderBottom:'2px solid #ccc',padding:'18px'}} value={this.state.password} onChange={this.setFormValue}/>
									
									<span style={{float:'right'}} className="mt-1">
									<a href="/updatepassword">Forgot Password</a></span>
									<br/>
									<br/>
									<p id="invalid-error" style={{display:'none'}}>Invalid user credentials.</p>
									<button className="lognbtn" onClick={this.doLogin}>Login</button>
							<span>By Continuing, I agree to the <a href="/term-conditions" style={{color:'#ee5f73'}}>Terms Of Use</a> and <a href="/privacy-policy"  style={{color:'#ee5f73 '}}>Privacy Policy</a> Having trouble logging in? <a href="/faq"  style={{color:'#ee5f73'}}>Get Help</a></span>
								</div>
							</div>
							<div className="col-md-3">
								<div className="social-login mb-4">
									<h4 className="mb-4 mt-2">Or Login Using</h4>
									<ul>
									
									{/* <GoogleLogin
										clientId="1035830236225-blfkritunkvb2vqkj82opjcgdqma5c4b.apps.googleusercontent.com"
										buttonText="Sign in With Google+"
										className="goglbtn"
										onSuccess={this.onSuccess}
										theme= "dark"
										onFailure={this.onFailure}
										cookiePolicy={'single_host_origin'}
									/> */}
									</ul>
								</div> 
							</div>
							<div className="col-md-3">
								<div className=" Login" style={{background: 'none', padding:'0px'}}>
								 <img className="mb-3" src="/images/coupens/coupon_code.png" width="100%" alt="inkmeso"/>
								<img className="mt-3" src="/images/coupens/DISCOUNT_COUPONS.jpg" width="100%"  alt="inkmeso"/>
								</div>
							</div>
						</div>
					</div>
				</div>
				</section>		  
			
		  </div>
		);
	}
	
	getReviewData()
	{
	 let qstr = '?typ=get_data&parent_id='+this.props.pid+'&cid='+this.props.cid;
	 let requestOptions = {
		  method: "Get",
		   headers: {
        'Content-Type': 'application/json; charset=UTF-8',
        'Access-Control-Allow-Origin':'*',
        'Access-Control-Allow-Headers':'*'
        }
		};
		fetch(API_HOST+'review.php'+qstr, requestOptions)
		.then(response => {
			if (response.ok) {
			   response.json().then(json =>{
				let response =  JSON.parse(JSON.stringify(json));
					this.setState({ReviewProducts:response});
				});
			}
		  }).catch((error) => {
			console.log(error);
		}); 
	}
	
	add_review()
	{ 
		let qstr = '?typ=save_review';
		fetch(API_HOST+'review.php'+qstr, {
              method: 'post',
              body: JSON.stringify({review: this.state.review === '' ? 'No Review' : this.state.review ,
				                      newRating:this.state.Rating,
									  category:this.props.category,
									  cid:this.props.cid,
									  sku:this.state.parent_sku,
									  parent_id:this.state.parent_id,
									  email1:this.state.email1,
									  first_name:this.state.first_name+" "+this.state.lname
									}),         
            }).then(response =>{
                if (response.ok){
                    response.json().then(json =>{
                       let response =  JSON.parse(JSON.stringify(json));
					   this.setState({review:'',activecolor:'#ffd700'}) ;
					   this.getReviewData();
					});
                }  
			})
	}
	
	
 render() { 
	var tovar_view_description_width='40%';
	var tovar_color_select_2='';
	
	if(this.props.productData['items'].length > 0 &&  this.props.productData['items'][this.props.productInformation].category_id.split(",")[1] == 162){
		tovar_view_description_width = '36%';
		tovar_color_select_2='tovar_color_select_2';
	} 
				
    return (
		Object.keys(this.props.productData['items']).length > 0 ?
			<div>
				<div className="clearfix padbot10">
					{this.props.productData['items'].length > 0 && this.props.productData['items'][this.props.productInformation].category_id.split(",")[1] == 162
						? 
							this.state.productImages.length > 0 &&  
							<Slider_2 
								sliderData={this.state.productImages}
								fImage={this.state.productImages[0].split("/").pop()}
								imageUrl={this.props.imageUrl}/>
						:
							this.state.productImages.length > 0 &&  
							<Slider 
								sliderData={this.state.productImages}
								isVideoClicked={this.state.isVideoClicked}
								videoClicked={this.videoClicked}
								cid={this.props.cid}
								pid={this.props.pid}
								fImage={this.state.productImages[0].split("/").pop()}
								imageUrl={this.props.imageUrl}/>
							}
					
					<div className="tovar_view_description" style={{width:tovar_view_description_width}}>
						<div className="breadcrum" >  
							{ReactHtmlParser(localStorage.getItem("currentCategory"))}
						</div>		
						
						<div className="tovar_view_title">{this.state.productTitle.replace(/[^a-z0-9 -&<.>/]/gi,' ')}</div>
						<div className="tovar_article">{this.state.sku}</div>
						<div className="clearfix tovar_brend_price">
							<div className="pull-left tovar_view_price">${this.state.price}</div>
						</div>
						
						<div className="tovar_size_select">
							<div className="clearfix">
								<p className="pull-left">Select SIZE   &nbsp;&nbsp;&nbsp;
								{this.state.sizeChartImage!='' && 
								<span style={{lineHeight:'20px',color:'rgb(10, 122, 236)',cursor:'pointer'}} onClick={()=>this.onOpenModal()}>Size Chart
								</span> 
								}
								</p>						 
							</div>
							
							<Modal open={this.state.open}  onClose={this.onCloseModal} >
								<img src={this.state.sizeChartImage} style={{width:'100%'}}/>
							</Modal>
							
							{this.showSizeOptions()}
						</div>
										
						{this.state.colorOptions &&
						<div className= {tovar_color_select_2 + " tovar_color_select"}>
								<p>Select color &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
									<span style={{color:'#ff2a6a'}}>&nbsp;{this.state.colorToShow} &nbsp;</span>
									<span style={{color:'red',fontSize:'14px'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; {this.state.outOfStock}</span> 
									
								</p>
								{this.showColorOptions()}
							</div>
						}
						
						
						<div className="product-options-bullets">
							<h4 style={{fontWeight:'bold','margin-bottom': '5px'}}>Specifications</h4>
							{(this.state.bpnt1 != '' && this.state.bpnt1 != null) ? ReactHtmlParser(this.state.bpnt1.replace(/[^a-z0-9 -&.</>]/gi,' ')) : ''}<br/>
							{(this.state.bpnt2 != '' && this.state.bpnt2 != null) ? ReactHtmlParser(this.state.bpnt2.replace(/[^a-z0-9 -&.</>]/gi,' ')) : ''}<br/>
							{(this.state.bpnt3 != '' && this.state.bpnt3 != null) ? ReactHtmlParser(this.state.bpnt3.replace(/[^a-z0-9 -&.</>]/gi,' ')) : ''}<br/>
							{(this.state.bpnt4 != '' && this.state.bpnt4 != null) ? ReactHtmlParser(this.state.bpnt4.replace(/[^a-z0-9 -&.</>]/gi,' ')) : ''}<br/>
							{(this.state.bpnt5 != '' && this.state.bpnt5 != null) ? ReactHtmlParser(this.state.bpnt5.replace(/[^a-z0-9 -&.</>]/gi,' ')) : ''}<br/>
							{(this.state.bpnt6 != '' && this.state.bpnt6 != null) ? ReactHtmlParser(this.state.bpnt6.replace(/[^a-z0-9 -&.</>]/gi,' ')) : ''}<br/>
							{(this.state.bpnt7 != '' && this.state.bpnt7 != null) ? ReactHtmlParser(this.state.bpnt7.replace(/[^a-z0-9 -&.</>]/gi,' ')) : ''}<br/>
							{(this.state.bpnt8 != '' && this.state.bpnt8 != null) ? ReactHtmlParser(this.state.bpnt8.replace(/[^a-z0-9 -&.</>]/gi,' ')) : ''}<br/>
						</div>
						
						
					</div>
					
				</div>
				
				</div>
			:
			<div style={{textAlign:'center'}}>
				<img src="/images/nodata.jpg"/>
			</div>
	) 	
  }
}