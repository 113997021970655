import React,{Component} from 'react';
import Modal from 'react-responsive-modal'; 
import ReactImageZoom from 'react-image-zoom';
import { SideBySideMagnifier, MOUSE_ACTIVATION,  TOUCH_ACTIVATION } from "react-image-magnifiers";
const imageUrl = 'https://bubsbliss.com/inkmesoimages/';
const largeImageUrl = 'https://bubsbliss.com/ibaweb/pub/media/catalog/product/';
const BUBSBLISS_IMAGE_BASE_URL = 'https://bubsbliss.com/ibaweb/pub/media/catalog/product/';
const SASSY_IMAGE_BASE_URL = 'https://s4sassy.com/ibaweb/pub/media/catalog/product/';

export default class Slider extends Component {
	constructor(props){
         super(props);
		 this.state = {
					open:false,
					productViewMainImage: '',
					ProductLargeImageUrl1: '',
					ZoomImageBaseUrl: SASSY_IMAGE_BASE_URL,
					ProductLargeImageUrl: '',
					preLoder: window.location.protocol + '//' +  window.location.host +"/images/preloader.gif",
					productMainImage : "",
					zoomWidth:550,
					//isVideoAvailable:'Inkmeso.mp4',
					isVideoAvailable:false,
					videoStillImage:'videostillimage.jpg',
			};	
			this.getVideoName = this.getVideoName.bind(this);
	}
	
	componentDidUpdate(prevProps, prevState) {
		let mainImageName=this.props.sliderData[0].split('/').pop();
		if(prevProps.fImage!=this.props.fImage){
			this.setState({productMainImage: imageUrl+this.props.sliderData[0].split('/').pop(),
			ProductLargeImageUrl:this.state.ZoomImageBaseUrl+mainImageName.charAt(0)+"/"+mainImageName.charAt(1)+"/"+mainImageName});
		}
				
		setTimeout(function(){
			//window.set_product_view_slider();
		},
		10
		);	
	}
	
	componentDidMount(){
		let mainImageName=this.props.sliderData[0].split('/').pop();
		let self=this;
		this.checkImageOnServer(mainImageName);
		this.getVideoName();
		setTimeout(function(){
			console.log(self.state.ZoomImageBaseUrl);
			self.setState({
				productMainImage: imageUrl+self.props.sliderData[0].split('/').pop(),
				ProductLargeImageUrl:self.state.ZoomImageBaseUrl+mainImageName.charAt(0)+"/"+mainImageName.charAt(1)+"/"+mainImageName
			});
		},
		100
		);
	}
	
	onOpenModal= (index)=>{	
		let file = this.props.sliderData[0];
		this.setState({productViewMainImage: largeImageUrl+file.charAt(0)+"/"+file.charAt(1)+"/"+file})
		
		this.setState({ open: true });	
	};
 
	onCloseModal = () => {
		this.setState({ open: false });
	};
	
	handelThumbnailClick(file){
		this.setState({productViewMainImage: ''},function(){
			this.setState({productViewMainImage: largeImageUrl+file.charAt(0)+"/"+file.charAt(1)+"/"+file});
		});
	}
	
	/* handelProductThumbnailHover = (src) =>{
		this.setState({productMainImage: src},function(){
			this.props.videoClicked(false);
		});
	} */
	
	
	handelProductThumbnailHover = (imageName) =>{
		let self=this;
		let src=imageUrl+imageName;
		this.setState(
		{
			productMainImage: src,
			ProductLargeImageUrl: this.state.ZoomImageBaseUrl+imageName.charAt(0)+"/"+imageName.charAt(1)+"/"+imageName},function(){
				this.props.videoClicked(false);
		});
	}
	
	
	checkImageOnServer(imageNameToCheckOnServer){
	let imageNameToCheckOnServer1=imageNameToCheckOnServer.charAt(0)+"/"+imageNameToCheckOnServer.charAt(1)+"/"+imageNameToCheckOnServer;
	let self=this;
	var https = require('https'),
	options = {method: 'head',
				host: 'bubsbliss.com',
				path: '/ibaweb/pub/media/catalog/product/'+imageNameToCheckOnServer1
			   
	},
	req = https.request(options, function(r) {
		let headerinfo= r.headers;
		console.log(headerinfo);
		if(r.headers['content-length']!=undefined){
			self.setState({ZoomImageBaseUrl:BUBSBLISS_IMAGE_BASE_URL});
		}
		else
			self.setState({ZoomImageBaseUrl:SASSY_IMAGE_BASE_URL});
	});
	req.end();
	}
	
	
	getVideoName(){
		let qstring = "?parent_Id=" + this.props.pid + "&category_Id=" + this.props.cid+"&action=gvideo&web=inkmeso";
		fetch('https://s4sassy.com/API_inkdotpot/idpMisc.php'+qstring, {
			method: 'GET',
		},
			).then(response => {
				if (response.ok)
				{
					response.json().then(json =>
					{
						if(json[0] != undefined){
							this.setState({
								isVideoAvailable:json[0]['Video_Name']},function(){
							})
						}
					});
				}
			}).catch((error) => {
			console.log(error);
		});
		if (window.innerWidth > 576) {
            this.setState({vertical:true})
        }else{
            this.setState({vertical:false})
        }
		
		
	}
	
	videoClicked(){
		this.setState({productMainImage: ''},function(){
			
		});
	}
	
	
	
	render() {
		let imageName1=this.state.productMainImage.split("/").pop();
		let largeImage=(this.state.ZoomImageBaseUrl+imageName1.charAt(0)+"/"+imageName1.charAt(1)+"/"+imageName1).toLowerCase();
		
    return (<div className="tovar_view_fotos clearfix">
				<div className="slider-box">
				<div id="slider2" className="">	
					<ul className="slides">						
						<div className="fluid">
								<div className="fluid__image-container" style={{background:'url('+this.state.preLoder+')'}}> 
									 
									 {/*<div className="product-image-box" 
										style={{backgroundImage:'url('+imageUrl+list.file.split("/").pop()+')',filter:'contrast(1.2)'}} 
										onClick={() => this.onOpenModal()} >
									 </div>*/}
									
									<div className="product-image-box" onClick={() => this.onOpenModal()} >
										{this.state.productMainImage!='' &&
										
										<SideBySideMagnifier
												imageSrc={this.state.productMainImage}
												imageAlt='HFGHFG'
												largeImageSrc={[largeImage,this.state.productMainImage]}
												className="product-zoom-image-box"
												alwaysInPlace={false}
												fillAvailableSpace={false}
											/>
										}

										{this.props.isVideoClicked  &&
											<div className="col-lg-12 col-sm-10 col-xs-12  order-up">
											<video 
											style={{width:'100%',minWidth: 'auto'}}
											controls autoplay muted  
											poster={"/images/videostillimages/"+this.state.videoStillImage} >
											<source src={"https://bubsbliss.com/iba-videos/"+this.state.isVideoAvailable} type="video/mp4" />
														Your browser does not support the video tag.
											</video> 
											</div>
										}
									</div>
										
									<p style={{'text-align':'center'}}>Click on image to view in HD qality</p>
								</div>
							</div>
					 						
					
					</ul>
				
					
					
				</div>
				<div id="carousel2" className="flexslider">
					<ul className="slides">
						{
							this.props.sliderData.map((list,i)=> 
							<li 
								style={{display:'block',backgroundImage:'url('+imageUrl+list.split("/").pop()+')',filter:'contrast(1.1)'}} 
								onMouseEnter={()=>this.handelProductThumbnailHover(list.split("/").pop())}
								onClick={()=>this.handelProductThumbnailHover(list.split("/").pop())}
							></li>)
						}	
						{this.state.isVideoAvailable &&
							<div className="video_icon" style={{border:'1px solid #999'}}>
								<img src="https://inkmeso.com/images/videoimg.jpg" class="icon_on_imgg"
								onMouseEnter={()=>{this.props.videoClicked(true);this.videoClicked()}}
									onClick={()=>{this.props.videoClicked(true);this.videoClicked()}}
								
								/>
							</div>
						}
					</ul>
				</div>
				</div>
				
				<Modal open={this.state.open}  onClose={this.onCloseModal} >
					<div id="product-image-view" className="product-image-view" style={{backgroundImage:'url('+this.state.preLoder+')'}}>
					{this.state.productViewMainImage &&					
						<img src={this.state.productViewMainImage}/>
					}
					</div> 
					<div className="product-image-view-thumbnail">
						<ul>
							{this.props.sliderData.map((list,i)=> 
								<li 
									data-url={largeImageUrl+list}
									style={{backgroundImage:'url('+imageUrl+list.split("/").pop()+')',filter:'contrast(1.1)'}} 
									onClick={(e) => this.handelThumbnailClick(list)}>
									&nbsp;
								</li>
							)}	
						</ul>	
					 </div>
				</Modal>
			</div>) 	
  }
}