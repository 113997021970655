import logo from './logo.svg';
import './App.css';
import React, { Component } from "react";



export default class App extends Component{



 render() {
	  return(
		 <div className="App">
	
      <header id="header" className="d-flex align-items-center fixed-top">
    <div className="container d-flex align-items-center justify-content-between">
     <a href="index.html1" className="logo"><img src="/img/ibalogo.JPG" alt=""/><span> IBA CRAFTS</span></a>

      <nav id="navbar" className="navbar">
        <ul>
          <li><a className="nav-link scrollto active" href="#hero">Home</a></li>
		  <li><a className="nav-link scrollto" href="#services">Services</a></li>
          <li><a className="nav-link scrollto " href="#portfolio">Catalog</a></li>
		  <li><a className="nav-link scrollto" href="#about">About</a></li>
		  <li><a className="nav-link scrollto" href="#contact">Contact</a></li>
		  <li><a className="nav-link scrollto" href="#contact">Partner Login</a></li>
          
        </ul>
        <i className="bi bi-list mobile-nav-toggle"></i>
      </nav>

    </div>
  </header>

  <section id="hero" className="d-flex align-items-center scrolled-offset">
    <div className="container" data-aos="zoom-out" data-aos-delay="100">
      <h1>Let’s Work Together to Do Amazing Things Together!</h1>
      <h2 className="text-center">Your Success is our success</h2>
    </div>
  </section>

  <main id="main">

    <section id="featured-services" className="featured-services">
		<div className="container">
			<div className="row">
				<div className="col-md-12">
			<h3 className="text-center">
				What if we told you that you don’t need to hold inventory? Sounds impossible, right? Watch us make the unbelievable happen!
			</h3>
			<p style={{margin:' 0 38px',fontSize:'17px',textAlign:' justify'}}>
				Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.
			</p>
		  </div>
			</div>
		</div>
	</section>
    <section id="featured-services" className="featured-services"  style={{background: '#d3d3d359'}}>
      <div className="container" data-aos="fade-up">
        <div className="row">
          <div className="col-md-6 col-lg-4 d-flex align-items-stretch mb-5 mb-lg-0">
            <div className="icon-box" data-aos="fade-up" data-aos-delay="100">
              
              <h4 className="title"><a href="">The power of our process   automation techniques</a></h4>
              <p className="description">At IBA Craft, it all happens right from scratch yet it takes very little time and that’s exactly we help our partners formulate stellar strategies. Imagine receiving the garment you ordered within just two days of letting your supplier know! As unbelievable as it sounds, it’s 100% true. Our amazing process automation techniques ensure that the garment is ready as soon as two days. We don’t believe in making things in anticipation which is why our JIT approach has been so successful. Needless to say, our partners can vouch for the world of opportunities we have opened their doors to!</p>
            </div>
          </div>

          <div className="col-md-6 col-lg-4 d-flex align-items-stretch mb-5 mb-lg-0">
            <div className="icon-box" data-aos="fade-up" data-aos-delay="200">
              
              <h4 className="title"><a href="">Kill two birds with one stone</a></h4>
              <p className="description">Are you tired of paying thousands in inventory holding costs only to bear the burden of unsold items later? Even the most resilient fashion brands today struggle to grapple with these problems. So, whether you’re a start-up eager to turn things around or an established company searching for viable ways that can cut down its costs, look no further. Working with IBA craft can be your one-way ticket to better production strategies, faster supply chain processes, and enhanced overall management. Plus, we guarantee that you won’t find the cost benefits we offer elsewhere!</p>
            </div>
          </div>

          <div className="col-md-6 col-lg-4 d-flex align-items-stretch mb-5 mb-lg-0">
            <div className="icon-box" data-aos="fade-up" data-aos-delay="300">
              
              <h4 className="title"><a href="">We are commercially viable</a></h4>
              <p className="description">Partnering with a company that requires inventory investment is a huge leap of faith. You’re investing your hard earned money and relying on a severely reused concept that may or may not yield results. With IBA Craft, you don’t have to worry about any of that! Our business model is commercially viable and backed by the best technologies across the globe!</p>
            </div>
          </div>
        </div>

      </div>
    </section>
	 </main>
    </div>
	  )
	  }
	  };



 
