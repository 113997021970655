import React, { Component } from "react";
import Header from './Header';
import Footer from './Footer';
import SiteServiceBanner from '../common/SiteServiceBanner';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export default class SiteService extends Component{

 render() {
	 
	  var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
	slidesToShow: window.innerWidth < 640 ? 1 : 1,
	adaptiveHeight:false,
	arrows:false,
	autoplay:true,
	autoplaySpeed:2000
  };
	  return(
	  
	<div className="SiteService">
	 <Header act="site"/>
	 <SiteServiceBanner/>
	  
	  <section className="crafts-box">
		<div className="container">
			<div className="row">
				<div className="col-md-12 col-lg-12">
					<div className="site_top_content">
						<h1 className="text-center">Why Iba Crafts?</h1>
						<p className="text-center"><b>What sets us apart?</b></p>
						<p>Over the years, we have succeeded in helping various partners expand their bottom lines and cut down costs. And the secret is simple! We use AR/VR technology to render a digital catalog for your business needs. Our state-of-the-art equipment and devices are imported from abroad, with some of the country’s most highly skilled individuals operating them. As an organization with ample experience working in the field of fashion, our business model has worked wonders for many and we guarantee it will do the same for you.</p>
					</div>
				</div>
			</div>
			
		</div>
		</section>
		<section className="collaborative crafts-box">
			<div className="container">
				<div className="row">
					<div className="col-md-6 col-xs-12">
						<div className="coleborate_design">
							<h5 className="collaborate_heading">
								<span className="category-squire"><i className="bi bi-app"></i></span>
								<span className="category-title">Collaborative Designing</span>
							</h5>
							<p>Our team of artists collaborates with the partner and their brand to come up with holistically appealing designs. This is followed up by a wholly integrative approval process whereby our partners have the option to select from a treasure trove of 20000 print designs enriched with 15 to 20 colors for each and 60 to 70 types of fabrics</p>
						</div>
					</div>
					<div className="col-md-6 col-xs-12">
						<div className="coleborate_design">
							<h5 className="collaborate_heading">
								<span className="category-squire"><i className="bi bi-app"></i></span>
								<span className="category-title">Logistics Assistance</span>
							</h5>
							<p>Through our extensive network of world-renowned carriers, we are able to cater all the partners across the globe with doorstep delivery that doesn’t take longer than 5 working days. 
							We can also deliver directly to your customer depending what suits you the best.</p>
						</div>
					</div>
					<div className="col-md-6 col-xs-12">
						<div className="coleborate_design">
							<h5 className="collaborate_heading">
								<span className="category-squire"><i className="bi bi-app"></i></span>
								<span className="category-title">Your Privacy Is Our Priority</span>
							</h5>
							<p>One of the things our partners laud us for is how well we maintain the privacy of their unique product designs. Needless to say, we never share their access with anyone outside the company. Thus, your brand is never jeopardized or at risk of copycats stealing your ideas when you work with us.</p>
						</div>
					</div>
					<div className="col-md-6 col-xs-12">
						<div className="coleborate_design">
							<h5 className="collaborate_heading">
								<span className="category-squire"><i className="bi bi-app"></i></span>
								<span className="category-title">Just In Time Manufacturing</span>
							</h5>
							<p>True clothing and fabric experts know that older garments, especially those sitting in the warehouse, aren’t as good as ones produced fresh. The threads have much more integrity and thus, the apparel sold to the customer lasts longer, thus, helping your brand get recognized for its high-quality standards!</p>
						</div>
					</div>
				</div>
			</div>
		</section>
		<section className="service_back_img">
			<div className="container">
				<div className="row">
					<div className="col-md-12 valu-proposition-box">
						<h1 className="text-center mb-3 mt-3 vale-pro-meda">Value Proposition For Your Customer</h1>

						<div className="colum_images  proportion_background">
							<div>
								<p className="pt-promo-box hover-type-2" data-hovercolors="" data-hovercolors-not="no-full-hover">
								<div className="promo-img-box text-center">
									<img className="lazyload" src="/img/service_site/squire_height.png" width="10%" />
									<img className="lazyload" src="/img/service_site/all_sizes.png" width="100%" />
								</div>
								<div className="promo-content mt-3">
								<span className="pt-title-underline" data-c="#333333" data-ac="#333333" data-borc="#333333" data-aborc="#333333" data-hover="Top Trending">All Sizes Available At All Time</span>
								</div>
								</p>
							</div>
						</div>

						<div className="colum_images  proportion_background">
							<div>
								<p className="pt-promo-box hover-type-2" data-hovercolors="" data-hovercolors-not="no-full-hover">
								<div className="promo-img-box text-center">
									<img className="lazyload" src="/img/service_site/squire_height.png" width="10%" />
									<img className="lazyload" src="/img/service_site/nevre_out.png" width="100%" />
								</div>
								<div className="promo-content mt-3">
								<span className="pt-title-underline" data-c="#333333" data-ac="#333333" data-borc="#333333" data-aborc="#333333" data-hover="Top Trending">Never Out Of Stock</span>
								</div>
								</p>
							</div>
						</div>

						<div className="colum_images  proportion_background">
							<div>
								<p className="pt-promo-box hover-type-2" data-hovercolors="" data-hovercolors-not="no-full-hover">
								<div className="promo-img-box text-center">
									<img className="lazyload" src="/img/service_site/squire_height.png" width="10%" />
									<img className="lazyload" src="/img/service_site/multiple_print.png" width="100%" />
								</div>
								<div className="promo-content mt-3">
								<span className="pt-title-underline" data-c="#333333" data-ac="#333333" data-borc="#333333" data-aborc="#333333" data-hover="Top Trending">Multiple Print & Color Options</span>
								</div>
								</p>
							</div>
						</div>

						<div className="colum_images  proportion_background">
							<div>
								<p className="pt-promo-box hover-type-2" data-hovercolors="" data-hovercolors-not="no-full-hover">
								<div className="promo-img-box text-center">
									<img className="lazyload" src="/img/service_site/squire_height.png" width="10%" />
									<img className="lazyload" src="/img/service_site/freshly_made.png" width="100%" />
								</div>
								<div className="promo-content mt-3">
								<span className="pt-title-underline" data-c="#333333" data-ac="#333333" data-borc="#333333" data-aborc="#333333" data-hover="Top Trending">Freshly Made Garment</span>
								</div>
								</p>
							</div>
						</div>
						
						<div className="colum_images  proportion_background contributes-size">
							<div>
								<p className="pt-promo-box hover-type-2" data-hovercolors="" data-hovercolors-not="no-full-hover">
								<div className="promo-img-box text-center">
									<img className="lazyload" src="/img/service_site/squire_height.png" width="10%" />
									<img className="lazyload" src="/img/service_site/contribute.png" width="100%" />
								</div>
								<div className="promo-content mt-3">
								<span className="pt-title-underline" data-c="#333333" data-ac="#333333" data-borc="#333333" data-aborc="#333333" data-hover="Top Trending">Contributes In Saving Water</span>
								</div>
								</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
		
		<section className="service_back_img mt-5">
			<div className="container">
				<div className="row">
					<div className="col-md-12 flex-mon-deg">
						<h1 className="text-center mb-5 mt-3 vale-pro-meda">Value Proposition For You</h1>
						<div className="colum_imagess  proportion_background">
							<div>
								<p className="pt-promo-box hover-type-2" data-hovercolors="" data-hovercolors-not="no-full-hover">
								<div className="promo-img-box text-center">
									<img className="lazyload" src="/img/service_site/squire_height.png" width="10%" />
									<img className="lazyload" src="/img/service_site/multiple_brands.png" width="100%" />
								</div>
								<div className="promo-content mt-3">
								<span className="pt-title-underline" data-c="#333333" data-ac="#333333" data-borc="#333333" data-aborc="#333333" data-hover="Top Trending">Create Multiple Brands Easily</span>
								</div>
								</p>
							</div>
						</div>
						<div className="colum_imagess  proportion_background">
							<div>
								<p className="pt-promo-box hover-type-2" data-hovercolors="" data-hovercolors-not="no-full-hover">
								<div className="promo-img-box text-center">
									<img className="lazyload" src="/img/service_site/squire_height.png" width="10%" />
									<img className="lazyload" src="/img/service_site/nevre_out.png" width="100%" />
								</div>
								<div className="promo-content mt-3">
								<span className="pt-title-underline" data-c="#333333" data-ac="#333333" data-borc="#333333" data-aborc="#333333" data-hover="Top Trending">You Will Never Go Out Of Stock</span>
								</div>
								</p>
							</div>
						</div>
						<div className="colum_imagess  proportion_background">
							<div>
								<p className="pt-promo-box hover-type-2" data-hovercolors="" data-hovercolors-not="no-full-hover">
								<div className="promo-img-box text-center">
									<img className="lazyload" src="/img/service_site/squire_height.png" width="10%" />
									<img className="lazyload" src="/img/service_site/all_sizes.png" width="100%" />
								</div>
								<div className="promo-content mt-3">
								<span className="pt-title-underline" data-c="#333333" data-ac="#333333" data-borc="#333333" data-aborc="#333333" data-hover="Top Trending">Offer Any Number Of Sizes</span>
								</div>
								</p>
							</div>
						</div>
						<div className="colum_imagess  proportion_background">
							<div>
								<p className="pt-promo-box hover-type-2" data-hovercolors="" data-hovercolors-not="no-full-hover">
								<div className="promo-img-box text-center">
									<img className="lazyload" src="/img/service_site/squire_height.png" width="10%" />
									<img className="lazyload" src="/img/service_site/keep_inventry.png" width="100%" />
								</div>
								<div className="promo-content mt-3">
								<span className="pt-title-underline" data-c="#333333" data-ac="#333333" data-borc="#333333" data-aborc="#333333" data-hover="Top Trending">No Need To Keep Inventory</span>
								</div>
								</p>
							</div>
						</div>
						<div className="colum_imagess  proportion_background">
							<div>
								<p className="pt-promo-box hover-type-2" data-hovercolors="" data-hovercolors-not="no-full-hover">
								<div className="promo-img-box text-center">
									<img className="lazyload" src="/img/service_site/squire_height.png" width="10%" />
									<img className="lazyload" src="/img/service_site/multiple_print.png" width="100%" />
								</div>
								<div className="promo-content mt-3">
								<span className="pt-title-underline" data-c="#333333" data-ac="#333333" data-borc="#333333" data-aborc="#333333" data-hover="Top Trending">Offer Multiple Print & Color Options</span>
								</div>
								</p>
							</div>
						</div>
						<div className="colum_imagess  proportion_background">
							<div>
								<p className="pt-promo-box hover-type-2" data-hovercolors="" data-hovercolors-not="no-full-hover">
								<div className="promo-img-box text-center">
									<img className="lazyload" src="/img/service_site/squire_height.png" width="10%" />
									<img className="lazyload" src="/img/service_site/reduce_staff.png" width="100%" />
								</div>
								<div className="promo-content mt-3">
								<span className="pt-title-underline" data-c="#333333" data-ac="#333333" data-borc="#333333" data-aborc="#333333" data-hover="Top Trending">Reduce Staff Costs</span>
								</div>
								</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
		<div className="container">
				<div className="row">
					<div className="col-md-12 text-center mt-4 mb-4">
						<h2 className="mt-4">If you are wondering what all we can make for you?</h2>
						<p className="possible-text" style={{padding:'0 52px',fontSize:'22px'}}>We can make everything that is possible in Just In Time. We want to give you a platform where you have a leasure to create multiple Brands in different categories and increse your sales. </p>
						{/*  */}
					</div>
				</div>
			</div>
		<section className="service_back_img">
		<h3 className="text-center men-cloth-head"><b>Men’s Clothing | Women’s Clothing | Kids Clothing | Home Furnishing</b></h3>
		<img className="lazyload" src="/img/service_site/value_grid.jpg" width="100%" />
			<h1 className="text-center mt-2 men-cloth-head">We can make everything for you!</h1>
		</section>
		
		<section className="work-box">
			<div className="container">
							<h4 className="how-head how-it-work">How it Works</h4>
							<p className="text_equal">With IBA Crafts, you can now enjoy JIT manufacturing service where we would not only build you a digital garment catalog for your brand and fill in the information you would share with us,<br/>
							But we also showcase your catalog with a life-like image with multiple options of prints/colors and embroiders.
							</p>

							<p className="text_equal">Every time you receive an order, our integrated API system will automatically punch all those orders in our system as we produce the same garment from scratch to dispatch within just 48 hours.
							</p>
							<p className="text_equal">Yes! You heard it right!</p>

							<p className="text_equal">We not only help you save warehousing costs and get over the problems of unsold inventory in the back, but we also give you a golden opportunity to leverage and list more designs with the help of our AR/VR technology in the front end and your downside protected at the backend if at all any design does not get sold since we are not manufacturing in anticipation.
							</p>

							<img className="" src="/img/just-text.png" alt ="product" width="80%" />

							<img className="product-img product-img-mar" src="/img/work-part.png" alt ="product" width="100%" />

							<h4 className="how-head best-part-head">The best part?</h4>
							<p className="text_equal">We do not charge anything upfront or in fact until you make a sale from the catalog, which means we invest first by building a catalog based on your requirements & type for your brand & only make money when you make money.</p>

							<p className="text_equal best-text-mar">1. We do not charge until there is a confirmed purchase for the product</p>
							<p className="text_equal best-text-mar">2. We work with you as your team to create your brand type product & catalog via AR/VR Tech & follow the strict privacy policy</p>
							<p className="text_equal best-text-mar">3. Manufacture garment after the order is received all in 48 hours</p>
							<p className="text_equal best-text-mar">4. Deliver anywhere in the world as per your timeline commitment to the customer</p>
							<p className="text_equal">5. No MOQ, we manufacture as low as one piece </p>
							<p className="text_equal">This is why our JIT model is perfect for eco-friendly fashion bands with a strong moral compass of responsibility.</p>
							<p className="text_equal">So what are you waiting for?</p>
							<p className="text_equal"><a className ="partner-set" href = "/contact" target="_blank" title="Contact Us"> Partner with us</a> to explore a treasure trove of opportunities.</p>
							
			</div>
		</section>
		<section id="featured-services" className="featured_bottom">
			<div className="container">
				<div className="row">
					<div className="col-md-12">
						<h3 className="text-center">
							STILL CAN’T IMAGINE?
						</h3>
						<p>
							Still Wondering, how your product would look like when you work in Just In Time model?
							Click on the below tab to see the possibilities of your workings with us.
						</p>
						<div className="cont_button text-center">
							<a href="/catalog" target="_blank" title="Catalog"><button className="btn btn-info">Catalog</button></a>
						</div>
					</div>
				</div>
			</div>
		</section>
		<Footer/>
		
    </div>
	  );
	  }
	  }
	  
	  
	  


 